import { ReactNode } from "react";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  flexRender,
  RowData,
  PaginationState,
  Updater,
  getPaginationRowModel,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { useState } from "react";
import { Spinner } from "./Spinner";

interface ComponentProps<T> {
  data: T[];
  columns: ColumnDef<T>[];
  handleRowClick?: (row: T) => void;
  onPaginationChange: (old: Updater<PaginationState>) => void;
  totalRows: number;
  currentPage: number;
  pageSize: number;
  loading?: boolean;
  showNoResult?: boolean;
  manualPagination?: boolean;
  defaultSorting?: { id: string; desc: boolean }[];
  noResultText?: string;
}

declare module "@tanstack/table-core" {
  //eslint-disable-next-line
  interface ColumnMeta<TData extends RowData, TValue> {
    hide?: string;
    ignoreRowClick?: boolean;
    sortable?: boolean;
  }
}
const BTN_CLASSES =
  "cursor-pointer inline-flex lg:w-[65px] md:w-[40px] w-[35px] lg:h-[37px] md:h-[35px] h-[30px] lg:py-2.5 md:py-2.5 py-2 px-2 m-1 lg:px-6 md:px-3 lg:mx-2 text-sm font-medium text-gray-900 focus:outline-none rounded-full border border-gray-200 hover:bg-gray-100 hover:text-zinc-800 focus:z-10 focus:ring-4 focus:ring-gray-200";

export const Table = <T extends object>({
  data,
  columns,
  handleRowClick,
  onPaginationChange,
  totalRows,
  currentPage,
  pageSize,
  loading,
  showNoResult,
  manualPagination,
  defaultSorting,
  noResultText,
}: ComponentProps<T>) => {
  const [sorting, setSorting] = useState<SortingState>(defaultSorting ?? []);
  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: onPaginationChange,
    manualPagination: manualPagination ?? true,
    pageCount: Math.ceil(totalRows / pageSize),
    state: {
      pagination: {
        pageIndex: currentPage,
        pageSize: pageSize,
      },
      sorting,
    },
  });

  if (data.length === 0) {
    if (showNoResult) {
      return <div className="p-2 w-full text-center">{noResultText ?? "No result"}</div>;
    }
    if (loading) {
      return (
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-6 h-6">
            <Spinner />
          </div>
        </div>
      );
    }
    return null;
  }

  let pagination: ReactNode = (
    <div className="grid grid-cols-1 md:flex items-center">
      <div className="flex-grow flex justify-center">
        <button className={BTN_CLASSES} onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
          <ChevronDoubleLeftIcon height="15px" width="15px" />
        </button>
        <button className={BTN_CLASSES} onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          <ChevronLeftIcon height="15px" width="15px" />
        </button>
        <span className="grid grid-cols-1 md:flex items-center gap-1">
          <div className="text-center">Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </strong>
        </span>
        <button className={BTN_CLASSES} onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          <ChevronRightIcon height="15px" width="15px" />
        </button>
        <button
          className={BTN_CLASSES}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <ChevronDoubleRightIcon height="15px" width="15px" />
        </button>
      </div>
      <div className="mx-2 md:mt-0 rounded-full justify-self-center md:justify-self-end bg-transparent">
        {totalRows} results
      </div>
      <select
        className="mt-2 md:mt-0 rounded-full justify-self-center md:justify-self-end w-[125px] bg-transparent"
        value={table.getState().pagination.pageSize}
        onChange={(e) => {
          table.setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
    </div>
  );

  if (totalRows <= 10) {
    pagination = null;
  }

  return (
    <div className="p-2 w-full">
      {pagination}
      <div>
        {table.getHeaderGroups().map((headerGroup) => (
          <div className="flex sm:flex md:flex justify-start flex-wrap border-b-2" key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <div
                key={header.id}
                className={`px-1 text-left flex-grow inline-flex font-bold ${
                  header.column.columnDef.meta?.hide ? `hidden ${header.column.columnDef.meta.hide}:block` : ""
                }
                ${header.column.columnDef.meta?.sortable ? "cursor-pointer" : ""}
                `}
                style={{
                  minWidth: header.column.columnDef.minSize,
                  maxWidth: header.column.columnDef.maxSize,
                  width: header.column.columnDef.size,
                }}
                {...(header.column.columnDef.meta?.sortable
                  ? { onClick: header.column.getToggleSortingHandler() }
                  : {})}
              >
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                {{
                  asc: <ChevronUpIcon className="ml-1 w-5 h-5" />,
                  desc: <ChevronDownIcon className="ml-1 w-5 h-5" />,
                }[header.column.getIsSorted() as string] ?? null}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="relative">
        {loading && (
          <div className="absolute flex justify-center items-center w-full h-full bg-gray-50/75">
            <div className="w-12 h-12">
              <Spinner />
            </div>
          </div>
        )}
        {table.getRowModel().rows.map((row, rowIndex) => (
          <div
            key={row.id}
            className={`flex justify-start border-b-2 items-stretch flex-wrap ${
              handleRowClick ? "cursor-pointer" : ""
            }`}
          >
            {row.getVisibleCells().map((cell) => (
              <div
                key={cell.id}
                className={`py-1 border-b items-center px-1 flex-grow ${
                  cell.column.columnDef.meta?.hide ? `hidden ${cell.column.columnDef.meta.hide}:flex` : "flex"
                } ${rowIndex % 2 === 0 ? "bg-gray-50/50" : ""}`}
                onClick={
                  !cell.column.columnDef.meta?.ignoreRowClick && handleRowClick
                    ? () => handleRowClick(row.original)
                    : () => {}
                }
                style={{
                  minWidth: cell.column.columnDef.minSize,
                  maxWidth: cell.column.columnDef.maxSize,
                  width: cell.column.columnDef.size,
                }}
              >
                <div className="w-full truncate" title={cell.getValue() as any}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div>
        {table.getFooterGroups().map((footerGroup) => (
          <div key={footerGroup.id}>
            {footerGroup.headers.map((header) => (
              <div key={header.id}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="mt-4">{pagination}</div>
    </div>
  );
};
