import LoadImage from "common/components/ImageLoader";
import { MainStats } from "items/components/MainStats";
import { Item } from "items/types";
import { Links } from "./Links";

export const Description = ({ data }: { data: Item }) => {
  return (
    <div>
      <div className="px-1 sm:px-none py-2 grid grid-cols-1 content-center md:grid-cols-2 xl:grid-cols-3 auto-cols-max bg-clip-border rounded-xl border">
        <div className="min-h-[400px] max-h-[400px] col-span-1 content-center justify-center grid min-w-[50px]">
          <LoadImage
            src={`https://cdn.warframestat.us/img/${data.imageName}`}
          />
        </div>
        <div className="xl:col-span-2 flex flex-col">
          <div className="grid lg:grid-cols-3 grid-cols-1">
            <div className="text-lg font-bold">{data.name}{data.systemName ? ` (${data.systemName})` : ""}{data.vaulted ? " (Vaulted)" : ""}</div>
            <div className="lg:justify-self-end lg:col-span-2 pb-1">
              <Links data={data} />
            </div>
          </div>
          <div>{data.description}</div>
          <div className="relative bottom-0 mt-auto justify-self-end pt-2">
            <MainStats data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};
