import { useRouter } from "react-router5";
import {
  ArrowRightOnRectangleIcon,
  ArrowLeftOnRectangleIcon,
} from "@heroicons/react/20/solid";
import { Auth } from "aws-amplify";
import { useAuthenticated } from "auth/hooks";

export const Logout = ({
  className,
  label,
}: {
  className?: string;
  label?: string;
}) => {
  const router = useRouter();
  const user = useAuthenticated();
  if (user === undefined) {
    return (
      <button
        onClick={() => router.navigate("login")}
        className={
          className
            ? className
            : "flex text-sm rounded-md px-4 py-2 whitespace-nowrap text-base font-medium"
        }
      >
        Sign in
        <ArrowLeftOnRectangleIcon className="ml-2 mr-1 h-5 w-5" />
      </button>
    );
  }

  const handleSignOut = async () => {
    await Auth.signOut();
    router.navigate("root", {}, { force: true });
  };

  return (
    <button
      className={className ? className : "flex text-sm rounded-md px-4 py-2"}
      title="Sign out"
      onClick={handleSignOut}
    >
      <ArrowRightOnRectangleIcon className="h-5 w-5 mr-1" />
      {label}
    </button>
  );
};
