import { useState } from "react";
import { useRouter } from "react-router5";
import { ColumnDef, PaginationState } from "@tanstack/react-table";

import { Item, Component } from "items/types";
import { navigateToItemByFgId } from "items/utils";
import LoadImage from "common/components/ImageLoader";
import { Mastery } from "items/components/Mastery";
import { Wishlist } from "items/components/Wishlist";
import { Acquired } from "items/components/Acquired";
import { Table } from "common/components/Table";

interface ComponentProps {
  data: (Item & Component)[];
}
const columns: ColumnDef<Item & Component>[] = [
  {
    id: "image",
    header: "",
    accessorKey: "imageName",
    cell: ({ getValue }) => {
      return (
        <div className="h-[60px]">
          <LoadImage src={`https://cdn.warframestat.us/img/${getValue()}`} />
        </div>
      );
    },
    size: 60,
    minSize: 60,
    maxSize: 60,
  },
  {
    id: "name",
    header: "Name",
    accessorKey: "name",
    size: 170,
    minSize: 170,
    maxSize: 200,
  },
  {
    id: "category",
    header: "Category",
    accessorKey: "category",
    size: 100,
    minSize: 100,
    maxSize: 100,
    meta: {
      hide: "sm",
    },
  },
  {
    id: "description",
    header: "Description",
    accessorKey: "description",
    minSize: 200,
    maxSize: Number.MAX_SAFE_INTEGER,
    meta: {
      hide: "md",
    },
  },
  {
    id: "itemCount",
    header: "Amount",
    accessorKey: "itemCount",
    size: 75,
    minSize: 75,
    maxSize: 75,
  },
  {
    id: "actions",
    accessorKey: "name",
    header: "",
    size: 80,
    minSize: 80,
    maxSize: 80,
    meta: {
      ignoreRowClick: true,
    },
    cell: ({ row }) => {
      return (
        <div className="grid grid-cols-3 w-[75px]">
          <div>
            <Mastery data={row.original} />
          </div>
          <div>
            <Wishlist data={row.original} />
          </div>
          <div>
            <Acquired data={row.original} />
          </div>
        </div>
      );
    },
  },
];

export const ChildComponent = ({ data }: ComponentProps) => {
  const router = useRouter();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  if (data.length === 0) {
    return null;
  }

  const handleRowClick = (item: Item) => {
    navigateToItemByFgId(router, item.fgId);
  };

  return (
    <div className="rounded-lg border p-2">
      <div className="text-lg font-bold">Component of</div>
      <Table<Item & Component>
        data={data}
        columns={columns}
        handleRowClick={handleRowClick}
        onPaginationChange={setPagination}
        totalRows={data.length}
        currentPage={pageIndex}
        pageSize={pageSize}
        manualPagination={false}
      />
    </div>
  );
};
