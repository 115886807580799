import { useState } from "react";
import { useAuthenticated } from "auth/hooks";
import { Alert, PasswordField } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

export const ChangePassword = () => {
  const user = useAuthenticated();
  const [succeeded, setSucceeded] = useState(false);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [currentPasswordMessage, setCurrentPasswordMessage] = useState<
    string | undefined
  >();
  const [newPasswordMessage, setNewPasswordMessage] = useState<
    string | undefined
  >();
  const [passwordConfirmationMessage, setPasswordConfirmationMessage] =
    useState<string | undefined>();

  const handleChangePassword = async () => {
    setCurrentPasswordMessage(undefined);
    setNewPasswordMessage(undefined);
    setPasswordConfirmationMessage(undefined);
    setErrorMessage(undefined);

    let hasError = false;
    if (currentPassword === "") {
      setCurrentPasswordMessage("Current password is required");
      hasError = true;
    }

    if (newPassword === "") {
      setNewPasswordMessage("New password is required");
      hasError = true;
    }

    if (passwordConfirmation === "") {
      setPasswordConfirmationMessage("New password confirmation is required");
      hasError = true;
    }

    if (hasError) {
      return;
    }

    if (newPassword !== passwordConfirmation) {
      setPasswordConfirmationMessage("New passwords do not match.");
      return;
    }

    await Auth.changePassword(user, currentPassword, newPassword)
      .then(() => {
        setSucceeded(true);
      })
      .catch((ex) => {
        setErrorMessage(ex.message);
      });
  };

  if (succeeded) {
    return <h1 className="font-2xl">Password changed successfully!</h1>;
  }

  return (
    <div>
      <h1>Fill in a new password to change your password</h1>
      {errorMessage !== undefined && (
        <Alert variation="error">{errorMessage}</Alert>
      )}
      <PasswordField
        descriptiveText="Please enter your current password"
        label="Current password"
        name="current_password"
        size="small"
        value={currentPassword}
        onChange={(event) => setCurrentPassword(event.currentTarget.value)}
        required
        errorMessage={currentPasswordMessage}
        hasError={currentPasswordMessage !== undefined}
      />
      <PasswordField
        descriptiveText="Please enter your new password"
        label="New password"
        name="new_password"
        size="small"
        value={newPassword}
        onChange={(event) => setNewPassword(event.currentTarget.value)}
        required
        errorMessage={newPasswordMessage}
        hasError={newPasswordMessage !== undefined}
      />
      <PasswordField
        descriptiveText="Please confirm your new password"
        label=""
        name="confirm_new_password"
        size="small"
        value={passwordConfirmation}
        onChange={(event) => setPasswordConfirmation(event.currentTarget.value)}
        required
        errorMessage={passwordConfirmationMessage}
        hasError={passwordConfirmationMessage !== undefined}
      />
      <button type="submit" onClick={handleChangePassword}>
        Change password
      </button>
    </div>
  );
};
