export const DucatsIcon = (props?: React.ComponentProps<"svg">) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    className="w-6 h-6"
    viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M278 550 c26 -25 31 -35 20 -41 -9 -6 -23 3 -46 29 -19 21 -31 29
-28 18 9 -25 -10 -45 -37 -39 -22 5 -22 5 -2 -10 17 -13 22 -14 36 -1 21 19
32 18 48 -7 10 -18 15 -19 22 -8 5 9 9 -34 9 -109 0 -97 -3 -127 -15 -139 -9
-8 -22 -12 -30 -9 -11 4 -15 -2 -15 -25 0 -36 -25 -49 -45 -23 -8 11 -14 24
-14 29 0 6 4 2 10 -7 22 -38 29 -15 29 95 l0 113 -55 54 c-51 50 -68 55 -46
12 22 -40 27 -61 31 -112 l3 -35 7 40 c5 31 7 21 7 -40 l0 -80 -20 45 c-10 25
-23 56 -28 70 -7 19 -8 16 -3 -15 2 -22 16 -62 29 -88 14 -27 25 -59 25 -72 0
-12 7 -28 15 -35 8 -7 15 -9 15 -5 0 4 6 2 14 -4 11 -10 16 -8 21 8 4 14 11
18 23 13 26 -9 50 -44 44 -62 -5 -12 -3 -13 9 -3 12 10 18 10 29 0 10 -10 12
-9 7 5 -3 10 -2 20 3 23 21 13 8 25 -25 25 -33 0 -34 1 -17 18 16 16 18 16 33
1 11 -11 26 -15 43 -12 17 4 28 0 32 -10 3 -9 12 -13 21 -10 11 4 14 2 9 -5
-4 -7 -13 -12 -21 -12 -7 0 -16 -8 -19 -17 -12 -44 -17 -83 -10 -83 13 0 33
35 34 57 0 12 8 26 17 31 9 6 18 18 19 28 1 11 5 30 9 44 l7 25 -20 -24 c-26
-32 -52 -22 -52 19 0 27 -3 30 -20 25 -19 -6 -20 -2 -20 128 0 148 8 170 50
147 22 -12 50 -7 50 8 0 5 -10 8 -22 8 -19 -1 -22 4 -20 24 l4 25 -34 -31
c-18 -17 -40 -30 -48 -29 -8 1 -21 3 -27 3 -7 1 -13 14 -13 28 0 20 -4 25 -16
20 -9 -4 -24 -1 -33 5 -9 7 -1 -3 17 -21z m79 -237 c-2 -37 -3 -9 -3 62 0 72
1 102 3 68 2 -34 2 -93 0 -130z m-77 -114 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10
16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m101 -12 c-8 -8 -11 -7 -11 4 0 20 13
34 18 19 3 -7 -1 -17 -7 -23z"
      />
      <path d="M340 561 c0 -17 7 -19 38 -12 5 1 -26 31 -32 31 -3 0 -6 -9 -6 -19z" />
      <path
        d="M482 467 l-52 -53 0 -112 c0 -62 3 -112 8 -112 24 0 92 116 92 157
-1 24 -4 21 -20 -21 -24 -61 -35 -71 -26 -24 4 20 2 38 -2 40 -5 1 -7 19 -4
38 l6 35 7 -35 c7 -32 7 -31 6 12 -1 26 2 46 6 45 8 -2 40 74 33 80 -1 1 -26
-21 -54 -50z"
      />
      <path d="M282 435 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
      <path d="M283 320 c0 -36 2 -50 4 -32 2 17 2 47 0 65 -2 17 -4 3 -4 -33z" />
      <path
        d="M198 121 c12 -12 22 -30 22 -40 0 -10 9 -29 20 -42 l19 -24 -6 55
c-3 30 -6 56 -7 56 -1 1 -17 5 -36 9 l-35 7 23 -21z"
      />
      <path d="M302 70 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
      <path d="M337 76 c-4 -10 -5 -21 -2 -24 9 -9 17 6 13 25 -3 17 -4 17 -11 -1z" />
    </g>
  </svg>
);
