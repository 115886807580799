import { toast } from "react-toastify";

import { Component, Item } from "items/types";
import { MarketIcon } from "items/images/MarketIcon";

interface ComponentProps {
  data: Item | Component;
  className?: string;
  showLabel?: boolean;
}

export const Market = ({ data, className, showLabel }: ComponentProps) => {
  const handleMarket = () => {
    if (!data.tradable) {
      toast(
        `${data.name} is not tradable, cannot provide a link to warframe.market.`,
        {
          type: "error",
        }
      );
      return;
    }
    let url_name = data.name.toLowerCase().replaceAll(" ", "_");
    window.open(`https://warframe.market/items/${url_name}`, "_blank");
  };

  const tradable = data.tradable;

  const tooltip = tradable ? "Go to warframe market" : "Item not tradable";
  if (showLabel) {
    className += " xl:w-[120px]";
  }
  return (
    <button
      title={tooltip}
      style={tradable ? {} : { opacity: 0.5 }}
      onClick={handleMarket}
      className={className}
    >
      <MarketIcon opacity={tradable ? "1" : "0.5"} />
      {showLabel && <span className="ml-2 mt-0.5 hidden xl:block">Market</span>}
    </button>
  );
};
