import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { client } from "common/client";
import { Abilities } from "items/components/Abilities";
import { Item } from "items/types";
import { Description } from "items/components/Description";
import { PatchLogs } from "items/components/PatchLogs";
import { Components } from "items/components/Components";
import { Drops } from "items/components/Drops";
import { Rewards } from "items/components/Rewards";
import { ChildComponent } from "items/components/ChildComponent";
import { LevelStats } from "items/components/LevelStats";
import { Attacks } from "items/components/Attacks";
import { useAuthenticated } from "auth/hooks";
import { defaultTitle } from "helmet";

interface ComponentProps {
  itemId?: string;
  name?: string;
}

export const ItemPage = ({ itemId, name }: ComponentProps) => {
  const [item, setItem] = useState<Item>();
  const user = useAuthenticated();

  useEffect(() => {
    if (itemId == null && name == null) {
      return;
    }

    let query: { [key: string]: string } = {};

    if (itemId) {
      const decodedId = window.atob(decodeURIComponent(itemId));
      query["fgId"] = decodedId;
    } else if (name) {
      query["name"] = decodeURIComponent(name);
    }
    let endpoint = "/api/items/all";
    if (user != null) {
      endpoint = "/api/user-items/all";
    }
    client.get(endpoint, query).then((result) => {
      setItem(result.data[0]);
    });
  }, [itemId, user, name]);
  if (item === undefined) {
    return null;
  }
  return (
    <div className="grid gap-2 pb-4">
      <Helmet>
        <title>
          {item.name} | {defaultTitle}
        </title>
      </Helmet>
      <Description data={item} />
      {item.abilities && item.passiveDescription && (
        <Abilities data={item.abilities} passiveDescription={item.passiveDescription} />
      )}
      {item.attacks && <Attacks data={item.attacks} />}
      {item.levelStats && <LevelStats data={item.levelStats.map((lvlStat) => lvlStat.stats[0])} />}
      {item.stats && <LevelStats data={item.stats} />}
      {item.components && <Components data={item.components} />}
      {item.componentOf && <ChildComponent data={item.componentOf} />}
      {item.rewards && <Rewards data={item.rewards} />}
      {item.drops && <Drops data={item.drops} card={true} />}
      {item.patchlogs && <PatchLogs data={item.patchlogs} />}
    </div>
  );
};
