export const Faq = () => {
  return (
    <div>
      <div className="font-bold">Who is behind framegrinder?</div>
      <div>
        Just a bunch of people that love to play warframe (maybe too much).
      </div>

      <div className="font-bold mt-2">
        Where did you get all item information?
      </div>
      <div>
        We make use of the{" "}
        <a
          href="https://github.com/WFCD/warframe-items"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          warframe-items
        </a>{" "}
        package from the guys over at WFCD:{" "}
        <a
          href="https://github.com/WFCD"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          https://github.com/WFCD
        </a>
        . A special thanks goes out to them!
      </div>

      <div className="font-bold mt-2">
        Can I somehow import my inventory from the game?
      </div>
      <div>
        Unfortunately there currently is no way to import any information about
        inventory or equipment from the game. So, grab something to drink and
        start clicking ;)
      </div>

      <div className="font-bold mt-2">Where can I leave my complaints?</div>
      <div>
        If you have any complaints about our emails or services please send a
        message to complaints@framegrinder.app
      </div>
    </div>
  );
};
