import { Switch } from "@headlessui/react";
import { capitalizeFirst } from "common/utils";

export const CATEGORIES = [
  "arcanes",
  "arch-gun",
  "arch-melee",
  "archwing",
  "fish",
  "gear",
  "glyphs",
  "melee",
  "misc",
  "mods",
  "node",
  "pets",
  "primary",
  "quests",
  "relics",
  "resources",
  "secondary",
  "sentinels",
  "skins",
  "warframes",
] as const;

export type Category = typeof CATEGORIES[number];

interface ComponentProps {
  category: Category;
  enabled: boolean;
  handleChange: (value: boolean) => void;
}

export const CategorySwitch = ({
  category,
  enabled,
  handleChange,
}: ComponentProps) => {
  return (
    <div>
      <Switch
        checked={enabled}
        onChange={handleChange}
        className={`${
          enabled ? "bg-zinc-600" : "bg-gray-200"
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span
          className={`${
            enabled ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
      <span className="ml-1">{capitalizeFirst(category)}</span>
    </div>
  );
};
