import {
  Authenticator,
  Button,
  CheckboxField,
  Theme,
  ThemeProvider,
  useAuthenticator,
  View,
} from "@aws-amplify/ui-react";
import { useAuthenticated } from "auth/hooks";
import { Amplify } from "aws-amplify";
import awsExports from "aws-export";
import { useState, useEffect } from "react";
import { useRouter } from "react-router5";

const theme: Theme = {
  name: "login",

  tokens: {
    colors: {
      brand: {
        primary: {
          "10": { value: "#f3f4f6" },
          "20": { value: "#e5e7eb" },
          "40": { value: "#d1d5db" },
          "60": { value: "#9ca3af" },
          "80": { value: "#4b5563" },
          "90": { value: "#111827" },
          "100": { value: "#111827" },
        },
      },
    },
    components: {
      checkboxfield: {
        alignItems: { value: "center" },
      },
    },
  },
};

export const Login = () => {
  const [checked, setChecked] = useState(false);
  const { route: authRoute } = useAuthenticator((context) => [context.route]);
  const user = useAuthenticated();
  const router = useRouter();

  useEffect(() => {
    if (user !== undefined) {
      router.navigate("profile");
    }
    if (authRoute !== "signIn") {
      return;
    }

    if (checked) {
      localStorage.setItem("fg-remember-me", "true");
    } else {
      localStorage.removeItem("fg-remember-me");
    }

    Amplify.configure({
      ...awsExports,
      storage: checked ? localStorage : sessionStorage,
    });
  }, [checked, authRoute, router, user]);

  const components = {
    SignIn: {
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <CheckboxField
              label="Remember me"
              name="remember"
              value="no"
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
            />
            <div className="mt-2">
              <Button
                fontWeight="normal"
                className="mt-2"
                onClick={toResetPassword}
                size="small"
                variation="link"
              >
                Forgot Password
              </Button>
            </div>
          </View>
        );
      },
    },
  };

  const formFields = {
    signIn: {
      username: {
        placeholder: "Enter your email address",
        label: "Email:",
      },
    },
    signUp: {
      username: {
        placeholder: "Enter your email address",
        label: "Email:",
      },
    },
    resetPassword: {
      username: {
        placeholder: "Enter your email address",
        label: "Email:",
      },
    },
  };
  return (
    <ThemeProvider theme={theme} colorMode="light">
      <h1 className="text-2xl">Login</h1>
      <Authenticator components={components} formFields={formFields} />
    </ThemeProvider>
  );
};
