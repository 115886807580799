import { useMemo } from "react";
import { toast } from "react-toastify";

import { Component } from "items/types";
import { Spinner } from "common/components/Spinner";
import { WishlistIcon } from "items/images/WishlistIcon";
import { inventoryUpdate } from "items/utils";
import { useAuthenticated } from "auth/hooks";

interface ComponentProps {
  data: Component[];
  setData: React.Dispatch<React.SetStateAction<Component[]>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BulkWishlist = ({
  data,
  setData,
  loading,
  setLoading,
}: ComponentProps) => {
  const user = useAuthenticated();

  const allWishlisted = useMemo(() => {
    return !data.some((value) => !value.wishlisted);
  }, [data]);

  const handleClick = async () => {
    if (!user) {
      toast(`Please sign in to add items to your wishlist.`, {
        type: "error",
      });
      return;
    }

    setLoading(true);
    const currentAllWishlisted = allWishlisted;
    const result = await Promise.all(
      data.map((component) => {
        if (component.wishlisted === currentAllWishlisted) {
          return inventoryUpdate(component.fgId, {
            wishlisted: !allWishlisted,
          });
        }
        return Promise.resolve(component);
      })
    );
    if (currentAllWishlisted) {
      toast("Components removed from your wishlist", {
        type: "success",
      });
    } else {
      toast("Components added to your wishlist", {
        type: "success",
      });
    }
    setLoading(false);
    const newData = [...data];
    result.forEach((component, index) => {
      newData[index].wishlisted = component.wishlisted;
    });
    setData(newData);
  };

  if (loading) {
    return (
      <div className="w-6 h-6">
        <Spinner />
      </div>
    );
  }

  let title = `${
    allWishlisted ? "Remove all components from" : "Add all components to"
  } your wishlist`;
  let opacity = allWishlisted ? "1" : "0.5";

  return (
    <button title={title} onClick={handleClick}>
      <WishlistIcon opacity={opacity} />
    </button>
  );
};
