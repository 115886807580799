// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2023-01-05T12:53:25.996Z

const awsmobile = {
    aws_cloud_logic_custom: [
        {
            endpoint: process.env.REACT_APP_AWS_ENDPOINT,
            name: 'ApiGatewayRestApi',
            region: 'eu-central-1'
        }
    ],
    aws_cognito_region: 'eu-central-1',
    aws_project_region: 'eu-central-1',
    aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
};

export default awsmobile;