import { Polarity as PolarityType } from "items/types";
import { capitalizeFirst } from "common/utils";

import { ReactComponent as MaduraiPolarity } from "items/images/MaduraiPolarity.svg";
import { ReactComponent as VazarinPolarity } from "../images/VazarinPolarity.svg";
import { ReactComponent as NaramonPolarity } from "../images/NaramonPolarity.svg";
import { ReactComponent as ZenurikPolarity } from "../images/ZenurikPolarity.svg";
import { ReactComponent as UnairuPolarity } from "../images/UnairuPolarity.svg";
import { ReactComponent as PenjagaPolarity } from "../images/PenjagaPolarity.svg";
import UmbraPolarity from "../images/UmbraPolarity.png";
import { ReactComponent as AuraPolarity } from "../images/AuraPolarity.svg";

const ICONS: { [key: string]: any } = {
  madurai: MaduraiPolarity,
  vazarin: VazarinPolarity,
  naramon: NaramonPolarity,
  zenurik: ZenurikPolarity,
  unairu: UnairuPolarity,
  penjaga: PenjagaPolarity,
  umbra: UmbraPolarity,
  aura: AuraPolarity,
};

export const Polarity = ({ polarity }: { polarity: PolarityType }) => {
  const name = capitalizeFirst(polarity);
  const loweredPolarity = polarity.toLowerCase(); // API has inconsistent casing

  const Icon = Object.keys(ICONS).includes(loweredPolarity)
    ? ICONS[loweredPolarity]
    : null;

  return (
    <div className="flex h-[20px]">
      {Icon != null && <Icon className="max-w-[20px]" />}
      {name}
    </div>
  );
};
