import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Component, InventoryResponse, Item } from "items/types";
import { Spinner } from "common/components/Spinner";
import { WishlistIcon } from "items/images/WishlistIcon";
import { inventoryUpdate } from "items/utils";
import { useAuthenticated } from "auth/hooks";

interface ComponentProps {
  data: Item | Component;
  handleResponse?: (value: Partial<InventoryResponse>) => void;
  loadingOverride?: boolean;
  className?: string;
  showLabel?: boolean;
}

export const Wishlist = ({
  data,
  handleResponse,
  loadingOverride,
  className,
  showLabel,
}: ComponentProps) => {
  const [wishlisted, setWishlisted] = useState(data.wishlisted);
  const [loading, setLoading] = useState(false);
  const user = useAuthenticated();

  useEffect(() => {
    setWishlisted(data.wishlisted);
  }, [data.wishlisted]);

  const handleClick = async () => {
    if (loading) {
      return;
    }
    if (!user) {
      toast(`Please sign in to add ${data.name} to your wishlist.`, {
        type: "error",
      });
      return;
    }
    setLoading(true);
    const newWishlisted = !wishlisted;
    const response = await inventoryUpdate(data.fgId, {
      wishlisted: newWishlisted,
    }).finally(() => setLoading(false));
    if (handleResponse) {
      handleResponse({ wishlisted: response.wishlisted, fgId: response.fgId });
    }
    setWishlisted(newWishlisted);
    if (newWishlisted) {
      toast(`${data.name} added to your wishlist`, {
        type: "success",
      });
    } else {
      toast(`${data.name} removed from your wishlist`, {
        type: "success",
      });
    }
  };

  let title = `${wishlisted ? "Remove from" : "Add to"} your wishlist`;
  let opacity = wishlisted ? "1" : "0.5";

  let icon = <WishlistIcon opacity={opacity} />;
  if (loadingOverride || loading) {
    icon = <Spinner />;
  }

  if (showLabel) {
    className += " xl:w-[120px]";
  }

  return (
    <button
      title={title}
      onClick={handleClick}
      style={{ opacity: opacity }}
      className={className || "w-6 h-6"}
    >
      {icon}
      {showLabel && (
        <span className="ml-2 mt-0.5 hidden xl:block">Wishlist</span>
      )}
    </button>
  );
};
