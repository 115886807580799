import { Fragment, useCallback, useMemo, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { client } from "common/client";
import { useRouter } from "react-router5";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import debounce from "lodash.debounce";
import { Spinner } from "./Spinner";
import { navigateToItemByFgId } from "items/utils";
import { useAuthenticated } from "auth/hooks";

export const SearchBox = ({ autoFocus }: { autoFocus?: boolean }) => {
  const router = useRouter();
  const user = useAuthenticated();

  const [searchValue, setSearchValue] = useState<string>("");
  const [result, setResult] = useState<{ [key: string]: string }[]>([]);
  const [loading, setLoading] = useState(false);

  const onSearch = useCallback(
    async (value: string) => {
      if (value !== "") {
        const query = {
          name: value,
          limit: 10,
          skipInventory: "true",
          skipComponents: "true",
        };
        let endpoint = "/api/items/all";
        if (user != null) {
          endpoint = "/api/user-items/all";
        }

        const result = await client.get(endpoint, query).finally(() => {
          setLoading(false);
          setResult([]);
        });
        setResult(result.data);
      } else {
        setResult([]);
      }

      setLoading(false);
    },
    [user]
  );

  const debouncedSearch = useMemo(() => {
    return debounce((value: string) => onSearch(value), 500);
  }, [onSearch]);

  const handleSelect = (entry: "NO_RESULT" | { [key: string]: string }) => {
    if (entry === "NO_RESULT") {
      return;
    }
    navigateToItemByFgId(router, entry.fgId);
    setSearchValue("");
    setResult([]);
  };

  return (
    <div className="relative w-full max-w-xs">
      <Combobox
        value={searchValue}
        onChange={(value: any) => handleSelect(value)}
      >
        <Combobox.Input
          placeholder="Search"
          autoFocus={autoFocus}
          onChange={(event) => {
            setSearchValue(event.target.value);
            setLoading(true);
            debouncedSearch(event.target.value);
          }}
          className="w-full py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-zinc-800 focus:z-10 focus:ring-4 focus:ring-gray-200"
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            // Prevent any interaction from happening regarding pressing enter
            // when request is still loading.
            // This seem to break interaction with the combobox component where the first item
            // isn't being selected by default anymore.
            if (event.key === "Enter" && loading) {
              event.preventDefault();
            }
          }}
        />
        <button className="absolute inset-y-0 top-[-2px] right-[15px] flex items-center rounded-r-md focus:outline-none">
          {loading ? (
            <div className="w-6 h-6">
              <Spinner />
            </div>
          ) : (
            <MagnifyingGlassIcon className="w-6 h-6 text-primary" />
          )}
        </button>
        {searchValue !== "" && !loading && (
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setSearchValue("")}
          >
            <Combobox.Options
              static
              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              {result.length === 0 && (
                <Combobox.Option
                  key="no-result"
                  value="NO_RESULT"
                  className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-primary hover:text-white"
                >
                  No result
                </Combobox.Option>
              )}
              {result.map((entry) => (
                <Combobox.Option
                  key={entry.fgId}
                  value={entry}
                  className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 ui-active:bg-primary ui-active:text-white hover:bg-primary hover:text-white"
                >
                  {entry.name}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        )}
      </Combobox>
    </div>
  );
};
