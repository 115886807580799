import { useState } from "react";
import { ColumnDef, PaginationState } from "@tanstack/react-table";
import { useRouter } from "react-router5";

import { Reward } from "items/types";
import { navigateToItemByName } from "items/utils";
import { Table } from "common/components/Table";

interface ComponentProps {
  data: Reward[];
}
const columns: ColumnDef<Reward>[] = [
  {
    id: "name",
    header: "Name",
    accessorFn: (row) => row.item.name,
    meta: {
      sortable: true,
    },
  },
  {
    id: "rarity",
    header: "Rarity",
    accessorKey: "rarity",
    meta: {
      sortable: true,
    },
  },
  {
    id: "chance",
    header: "Drop chance",
    accessorFn: (row) => row.chance.toFixed(2) + "%",
    sortingFn: "alphanumeric",
    meta: {
      sortable: true,
    },
  },
];

export const Rewards = ({ data }: ComponentProps) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const router = useRouter();
  if (data.length === 0) {
    return null;
  }
  return (
    <div className="rounded-lg border p-2">
      <div className="text-lg font-bold">Rewards</div>
      <Table<Reward>
        data={data}
        columns={columns}
        handleRowClick={(row) =>
          navigateToItemByName(
            router,
            row.item.warframeMarket
              ? row.item.warframeMarket.urlName.replace("_", " ")
              : row.item.name
          )
        }
        onPaginationChange={setPagination}
        totalRows={data.length}
        currentPage={pageIndex}
        pageSize={pageSize}
        manualPagination={false}
        defaultSorting={[{ id: "chance", desc: true }]}
      />
    </div>
  );
};
