import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { client } from "common/client";
import { Component, InventoryResponse, Item } from "items/types";
import { Spinner } from "common/components/Spinner";
import { AcquiredIcon } from "items/images/AcquiredIcon";
import { useAuthenticated } from "auth/hooks";

interface ComponentProps {
  data: Item | Component;
  handleResponse?: (value: Partial<InventoryResponse>) => void;
  loadingOverride?: boolean;
  className?: string;
  showLabel?: boolean;
}

export const Acquired = ({
  data,
  loadingOverride,
  handleResponse,
  className,
  showLabel,
}: ComponentProps) => {
  const [acquired, setAcquired] = useState(data.acquired);
  const [loading, setLoading] = useState(false);
  const user = useAuthenticated();

  useEffect(() => {
    setAcquired(data.acquired);
  }, [data.acquired]);

  const handleClick = async () => {
    if (loading) {
      return;
    }
    if (!user) {
      toast(`Please sign in to mark ${data.name} as acquired.`, {
        type: "error",
      });
      return;
    }
    setLoading(true);
    const newAcquired = !acquired;
    const postData: { [key: string]: string | boolean } = {
      fgId: data.fgId,
      acquired: newAcquired,
    };
    if (newAcquired) {
      postData["wishlisted"] = false;
    }
    const response = await client
      .put("/api/inventory", postData)
      .finally(() => setLoading(false));

    if (handleResponse) {
      handleResponse({
        acquired: response.acquired,
        wishlisted: response.wishlisted,
        fgId: response.fgId,
      });
    }

    setAcquired(newAcquired);
    if (newAcquired) {
      toast(
        `${data.name} marked as acquired ${
          data.wishlisted ? "and removed from your wishlist" : ""
        }`,
        {
          type: "success",
        }
      );
    } else {
      toast(`${data.name} unmarked as acquired`, {
        type: "success",
      });
    }
  };

  let title = `${acquired ? "Unmark" : "Mark"} as acquired`;
  let opacity = acquired ? "1" : "0.5";

  let icon = <AcquiredIcon opacity={opacity} />;

  if (loadingOverride || loading) {
    icon = <Spinner />;
  }

  if (showLabel) {
    className += " xl:w-[130px]";
  }

  return (
    <button
      title={title}
      onClick={handleClick}
      className={className || "w-6 h-6"}
      style={{ opacity: opacity }}
    >
      {icon}
      {showLabel && (
        <span className="ml-2 mt-0.5 hidden xl:block">Acquired</span>
      )}
    </button>
  );
};
