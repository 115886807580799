import { useState } from "react";
import { ColumnDef, PaginationState } from "@tanstack/react-table";

import { PatchLog } from "items/types";
import { Table } from "common/components/Table";

interface ComponentProps {
  data: PatchLog[];
}

const columns: ColumnDef<PatchLog>[] = [
  {
    id: "name",
    header: "Name",
    accessorKey: "name",
    size: 200,
    minSize: 200,
    maxSize: 400,
  },
  {
    id: "date",
    header: "Date",
    accessorFn: (row) => row.date.split("T")[0],
    size: 100,
    minSize: 100,
    maxSize: 100,
  },
  {
    id: "fixes",
    header: "",
    cell: ({ row: tableRow }) => {
      const row = tableRow.original;
      return (
        <div>
          {row.fixes !== "" && (
            <div>
              <div className="font-bold">Fixes</div>
              <div>{row.fixes}</div>
            </div>
          )}
          {row.changes !== "" && (
            <div>
              <div className="font-bold">Changes</div>
              <div>{row.changes}</div>
            </div>
          )}
          {row.additions !== "" && (
            <div>
              <div className="font-bold">Additions</div>
              <div>{row.additions}</div>
            </div>
          )}
        </div>
      );
    },
    minSize: 200,
    maxSize: Number.MAX_SAFE_INTEGER,
  },
];

export const PatchLogs = ({ data }: ComponentProps) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  return (
    <div className="rounded-lg border p-2">
      <div className="text-lg font-bold">Patch logs</div>
      <Table<PatchLog>
        data={data}
        columns={columns}
        onPaginationChange={setPagination}
        totalRows={data.length}
        currentPage={pageIndex}
        pageSize={pageSize}
        manualPagination={false}
      />
    </div>
  );
};
