import { useState } from "react";
import { ColumnDef, PaginationState } from "@tanstack/react-table";
import { useRouter } from "react-router5";

import { Drop } from "items/types";
import { navigateToItemByName } from "items/utils";
import { Table } from "common/components/Table";

interface ComponentProps {
  data: Drop[];
  card?: boolean;
}

const columns: ColumnDef<Drop>[] = [
  {
    id: "location",
    header: "Location",
    accessorKey: "location",
    minSize: 200,
    maxSize: Number.MAX_SAFE_INTEGER,
    meta: {
      sortable: true,
    },
  },
  {
    id: "type",
    header: "Type",
    accessorKey: "type",
    size: 250,
    minSize: 250,
    maxSize: 250,
    meta: {
      sortable: true,
    },
  },
  {
    id: "rarity",
    header: "Rarity",
    accessorKey: "rarity",
    size: 125,
    minSize: 125,
    maxSize: 125,
  },
  {
    id: "rotation",
    header: "Rotation",
    accessorFn: (row) => row.rotation || "-",
    size: 100,
    minSize: 100,
    maxSize: 100,
  },
  {
    id: "chance",
    header: "Drop chance",
    accessorFn: (row) => (row.chance * 100).toFixed(2) + "%",
    sortingFn: "alphanumeric",
    size: 150,
    minSize: 150,
    maxSize: 150,
    meta: {
      sortable: true,
    },
  },
];

export const Drops = ({ data, card }: ComponentProps) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const router = useRouter();
  const handleRowClick = (row: Drop) => {
    if (!row.location.includes("Relic")) {
      return;
    }
    const split = row.location.split(" Relic");
    let rarity = "Intact";
    if (split[1] !== "") {
      rarity = split[1].replace(" (", "").replace(")", "");
    }
    navigateToItemByName(router, split[0] + " " + rarity);
  };

  if (data.length === 0) {
    return null;
  }

  const table = (
    <Table<Drop>
      data={data}
      columns={columns}
      handleRowClick={handleRowClick}
      onPaginationChange={setPagination}
      totalRows={data.length}
      currentPage={pageIndex}
      pageSize={pageSize}
      manualPagination={false}
      defaultSorting={[{ id: "chance", desc: true }]}
    />
  );

  if (!card) {
    return table;
  }

  return (
    <div className="rounded-lg border p-2">
      <div className="text-lg font-bold">Drop locations</div>
      <div>{table}</div>
    </div>
  );
};
