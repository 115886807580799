import { useState } from "react";
import { ColumnDef, PaginationState } from "@tanstack/react-table";

import { Attack } from "items/types";
import { Table } from "common/components/Table";
import { capitalizeFirst } from "common/utils";

interface ComponentProps {
  data: Attack[];
}

const columns: ColumnDef<Attack>[] = [
  {
    id: "name",
    header: "Name",
    accessorKey: "name",
  },
  {
    id: "crit_chance",
    header: "Crit. Chance",
    accessorFn: (row) => row.crit_chance + "%",
  },
  {
    id: "crit_mult",
    header: "Crit. Multiplier",
    accessorFn: (row) => row.crit_mult + "x",
  },
  {
    id: "status_chance",
    header: "Status chance",
    accessorFn: (row) => row.status_chance + "%",
  },
  {
    id: "speed",
    header: "Speed",
    accessorKey: "speed",
  },
  {
    id: "shot_type",
    header: "Shot type",
    accessorFn: (value) => value.shot_type || "-",
  },
  {
    id: "damage",
    header: "Damage",
    accessorKey: "damage",
    cell: ({ row }) => {
      let value = row.original;
      const result = [];
      Object.keys(value.damage).forEach((damageType, index) => {
        result.push(
          <p key={index}>{`${capitalizeFirst(damageType)}: ${
            value.damage[damageType]
          }`}</p>
        );
      });
      if (value.slide) {
        result.push(<p key="slide">{`Slide: ${value.slide}`}</p>);
      }
      if (value.slam) {
        result.push(<p key="slam-1">{`Slam: ${value.slam.damage}`}</p>);
        result.push(
          <p key="slam-2">
            {`Radial: ${value.slam.radial.damage} ${
              value.slam.radial.radius
                ? "(" + value.slam.radial.radius + "m)"
                : ""
            } ${
              value.slam.radial.element
                ? "(" + value.slam.radial.element + ")"
                : ""
            }`}
          </p>
        );
      }
      return result;
    },
  },
];

export const Attacks = ({ data }: ComponentProps) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = (
    <Table<Attack>
      data={data}
      columns={columns}
      onPaginationChange={setPagination}
      totalRows={data.length}
      currentPage={pageIndex}
      pageSize={pageSize}
      manualPagination={false}
    />
  );

  return (
    <div className="rounded-lg border p-2">
      <div className="text-lg font-bold">Attacks</div>
      <div>{table}</div>
    </div>
  );
};
