import { Ability } from "items/types";

interface ComponentProps {
  data: Ability[];
  passiveDescription: string;
}

export const Abilities = ({ data, passiveDescription }: ComponentProps) => {
  return (
    <div className="rounded-lg border p-2">
      <div className="text-lg font-bold">Abilities</div>
      <div className="inline-flex">
        <div className="font-bold mr-1">Passive:</div>
        {passiveDescription}
      </div>
      <div className="grid grid-cols-2 gap-2 xl:grid-cols-4">
        {data.map((ability, index) => {
          return (
            <div key={index}>
              <div className="mr-2 font-bold">{ability.name}</div>
              <div>{ability.description}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
