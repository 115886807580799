import { useAuthenticated } from "auth/hooks";
import LoadImage from "common/components/ImageLoader";
import { useRouter } from "react-router5";
import banner from "../images/banner.png";

export const RootPage = () => {
  const router = useRouter();
  const authenticated = useAuthenticated();

  return (
    <>
      <div className="h-auto">
        <LoadImage src={banner} />
      </div>
      <div className="mt-5 xl:pr-4">
        <h1 className="text-xl font-bold mb-1">
          The extensive online reference for Warframe items
        </h1>
        <p>
          FrameGrinder is the ultimate tool in every Warframe enthusiast’s
          arsenal. With FrameGrinder, you have a reliable item database of all
          the game’s items at your disposal. Crazy about collecting and
          levelling all your WF items? It just takes a few clicks to track the
          items you want, see which ones you need to level and view which ones
          you still need to collect. The vast world of Warframe can be
          overwhelming, which is why our goal is to help players get an optimal
          overview of the items in the game.
        </p>

        <h1 className="text-xl font-bold mt-6 mb-1">
          How many items are there in Warframe?
        </h1>
        <p>
          With over 3,000 items in Warframe, collecting and levelling them all
          is not an easy task. Luckily, at FrameGrinder we’ve devoted ourselves
          to creating an organised database of all of Warframe’s items. This way
          you can easily see which items you still need to collect, and marvel
          at the beauty of the ones you collected.
        </p>

        <h1 className="text-xl font-bold mt-6 mb-1">
          Can I keep track of my item collection with FrameGrinder?
        </h1>
        <p>
          Yes. We’ve made the item database interactive, which means you can put
          the items you still need on your wishlist, and cross off the ones you
          collected.
        </p>

        <h1 className="text-xl font-bold mt-6 mb-1">
          Do I have to pay to use FrameGrinder?
        </h1>
        <p>
          No. FrameGrinder is 100% free. FrameGrinder is not commercial and was
          set up purely as a hobby project by Warframe enthusiasts just like
          you!
        </p>

        {!authenticated && (
          <p className="pt-4">
            Sign up to keep track of all your Warframe items. It’s 100% free!
            <button
              className="ml-2 inline-flex py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-zinc-800 focus:z-10 focus:ring-4 focus:ring-gray-200 "
              onClick={() => router.navigate("login")}
            >
              Sign up
            </button>
          </p>
        )}
      </div>
    </>
  );
};
