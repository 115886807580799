import { Mastery } from "items/components/Mastery";
import { Wishlist } from "items/components/Wishlist";
import { Acquired } from "items/components/Acquired";
import { InventoryResponse, Item } from "items/types";
import { useEffect, useState } from "react";

export const AdvancedSearchRowLinks = ({ row }: { row: Item }) => {
  const [localData, setLocalData] = useState<Item>(row);

  useEffect(() => {
    setLocalData(row);
  }, [row]);

  const handleResponse = (response: Partial<InventoryResponse>) => {
    setLocalData((value) => {
      return { ...value, ...response };
    });
  };

  return (
    <div className="grid grid-cols-3 w-[75px]">
      <div>
        <Mastery data={localData} handleResponse={handleResponse} />
      </div>
      <div>
        <Wishlist data={localData} handleResponse={handleResponse} />
      </div>
      <div>
        <Acquired data={localData} handleResponse={handleResponse} />
      </div>
    </div>
  );
};
