import { useEffect, useState } from "react";
import { CheckIcon, MinusIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { Component, InventoryResponse } from "items/types";
import { Dialog } from "common/components/Dialog";
import LoadImage from "common/components/ImageLoader";
import { Drops } from "./Drops";
import { Market } from "./Market";
import { Wishlist } from "./Wishlist";
import { Acquired } from "./Acquired";
import { navigateToItemByFgId } from "items/utils";
import { useRouter } from "react-router5";
import { useCallback } from "react";
import { BulkWishlist } from "./BulkWishlist";
import { BulkAcquire } from "./BulkAcquire";
import { DropsIcon } from "./DropsIcon";
import { DucatsIcon } from "./DucatsIcon";
import { TradableIcon } from "./TradableIcon";

interface ComponentProps {
  data: Component[];
}

export const Components = ({ data }: ComponentProps) => {
  const router = useRouter();

  useEffect(() => {
    setCurrentComponents(data);
  }, [data]);

  const [currentComponents, setCurrentComponents] = useState<Component[]>(data);
  const [toggleWishlistedLoading, setToggleWishlistedLoading] = useState(false);
  const [toggleAcquiredLoading, setToggleAcquiredLoading] = useState(false);

  const handleRowClick = useCallback(
    (row: Component) => {
      navigateToItemByFgId(router, row.fgId);
    },
    [router]
  );

  if (data.length === 0) {
    return null;
  }

  const handleSingleToggleResponse = (
    inventoryResponse: Partial<InventoryResponse>
  ) => {
    const newComponents = [...currentComponents];
    let matchingComponent = currentComponents.find(
      (component) => component.fgId === inventoryResponse.fgId
    );
    if (matchingComponent === undefined) {
      return;
    }
    if (Object.keys(inventoryResponse).includes("wishlisted")) {
      matchingComponent.wishlisted = inventoryResponse.wishlisted;
    }
    if (Object.keys(inventoryResponse).includes("acquired")) {
      matchingComponent.acquired = inventoryResponse.acquired;
    }

    console.log(newComponents);
    setCurrentComponents(newComponents);
  };

  return (
    <div className="rounded-lg border p-2">
      <div className="text-lg font-bold">Components</div>
      <div className="w-full overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="border-b-2">
              <td className="hidden sm:block min-w-[50px] max-w-[100px]"></td>
              <td className="min-w-[150px]">Name</td>
              <td className="hidden lg:table-cell min-w-[500px]">
                Description
              </td>
              <td className="min-w-[50px]">
                <span className="hidden md:block">Amount</span>
                <span className="block md:hidden">Amt.</span>
              </td>
              <td className="min-w-[50px]">
                <span className="hidden md:block">Ducats</span>
                <span className="block md:hidden">
                  <DucatsIcon />
                </span>
              </td>
              <td className="min-w-[25px] md:min-w-[50px]">
                <span className="hidden md:block">Tradable</span>
                <span className="block md:hidden">
                  <TradableIcon />
                </span>
              </td>
              <td className="min-w-[25px] md:min-w-[50px]">
                <span className="hidden md:block">Drops</span>
                <span className="block md:hidden">
                  <DropsIcon />
                </span>
              </td>
              <td className="min-w-[25px] sm:min-w-[75px]">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                  <div></div>
                  <BulkWishlist
                    data={currentComponents}
                    setData={setCurrentComponents}
                    loading={toggleWishlistedLoading}
                    setLoading={setToggleWishlistedLoading}
                  />
                  <BulkAcquire
                    data={currentComponents}
                    setData={setCurrentComponents}
                    loading={toggleAcquiredLoading}
                    setLoading={setToggleAcquiredLoading}
                  />
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {currentComponents.map((component, index) => {
              return (
                <tr className="border-b-2 cursor-pointer" key={index}>
                  <td
                    className="hidden sm:block max-h-[50px]"
                    onClick={() => handleRowClick(component)}
                  >
                    <LoadImage
                      src={`https://cdn.warframestat.us/img/${component.imageName}`}
                    />
                  </td>
                  <td
                    className="mr-2 text-sm sm:text-base sm:font-bold truncate max-w-[1px]"
                    title={component.name}
                    onClick={() => handleRowClick(component)}
                  >
                    {component.name}
                  </td>
                  <td
                    className="hidden lg:table-cell truncate max-w-[1px]"
                    title={component.description}
                    onClick={() => handleRowClick(component)}
                  >
                    {component.description}
                  </td>
                  <td onClick={() => handleRowClick(component)}>
                    {component.itemCount}
                  </td>
                  <td onClick={() => handleRowClick(component)}>
                    {component.ducats || <MinusIcon className="w-5 h-5" />}
                  </td>
                  <td onClick={() => handleRowClick(component)}>
                    {component.tradable ? (
                      <CheckIcon className="w-5 h-5" />
                    ) : (
                      <XMarkIcon className="w-5 h-5" />
                    )}
                  </td>
                  <td>
                    {component.drops && component.drops.length > 0 && (
                      <div className="flex">
                        <Dialog
                          title="Drop locations"
                          content={<Drops data={component.drops} />}
                          buttonContent={<DropsIcon />}
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    <div className="grid grid-cols-1 py-2 sm:py-none sm:grid-cols-3 gap-2">
                      <Market data={component} />
                      <Wishlist
                        data={component}
                        handleResponse={handleSingleToggleResponse}
                        loadingOverride={toggleWishlistedLoading}
                      />
                      <Acquired
                        data={component}
                        handleResponse={handleSingleToggleResponse}
                        loadingOverride={toggleAcquiredLoading}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
