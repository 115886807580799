import { useAuthenticated } from "auth/hooks";

interface ComponentProps {
  value: boolean | null;
  setter: React.Dispatch<React.SetStateAction<boolean | null>>;
}

export const CheckboxFilter = ({ value, setter }: ComponentProps) => {
  const user = useAuthenticated();
  const title = !user ? "Only available when signed in" : "";
  return (
    <div className="flex items-center mb-4">
      <input
        id="yes"
        disabled={!user}
        type="checkbox"
        checked={value || false}
        title={title}
        value=""
        onChange={() => null}
        onClick={() => setter(value === true ? null : true)}
        className="form-checkbox w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 checked:focus:bg-zinc-600 checked:bg-zinc-600 checked:hover:bg-zinc-400 checked:focus:bg-zinc-600 focus:ring-zinc-400 focus:ring-2"
      />
      <label
        htmlFor="yes"
        title={title}
        className={`ml-2 text-sm font-medium text-gray-900 ${
          !user ? "opacity-50" : ""
        }`}
      >
        Yes
      </label>
      <input
        id="no"
        type="checkbox"
        disabled={!user}
        title={title}
        checked={value === false || false}
        onChange={() => null}
        value=""
        onClick={() => setter(value === false ? null : false)}
        className="ml-3 form-checkbox w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 checked:focus:bg-zinc-600 checked:bg-zinc-600 checked:hover:bg-zinc-400 checked:focus:bg-zinc-600 focus:ring-zinc-400 focus:ring-2"
      />
      <label
        htmlFor="no"
        title={title}
        className={`ml-2 text-sm font-medium text-gray-900 ${
          !user ? "opacity-50" : ""
        }`}
      >
        No
      </label>
    </div>
  );
};
