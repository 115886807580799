import { BookOpenIcon } from "@heroicons/react/24/outline";

import { Item } from "items/types";

interface ComponentProps {
  data: Item;
  className?: string;
  showLabel?: boolean;
}

const WIKI_BASE = "https://warframe.fandom.com/wiki/Special:Search?query=";

export const Wiki = ({ data, className, showLabel }: ComponentProps) => {
  const handleWiki = () => {
    if (data.wikiaUrl == null) {
      window.open(WIKI_BASE + data.name.replace(" ", "+"), "_blank");
      return;
    }
    window.open(data.wikiaUrl, "_blank");
  };

  const tooltip = "Go to wiki page";
  if (showLabel) {
    className += " xl:w-[100px]";
  }
  return (
    <button title={tooltip} onClick={handleWiki} className={className}>
      <BookOpenIcon className="w-6 h-6" />
      {showLabel && <span className="ml-2 mt-0.5 hidden xl:block">Wiki</span>}
    </button>
  );
};
