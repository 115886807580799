import { Faq } from "common/pages/faq";
import { PrivacyPolicy } from "common/pages/privacyPolicy";
import { RootPage } from "common/pages/rootPage";

const routes = [
  {
    title: null,
    name: "root",
    path: "/",
    render: () => <RootPage />,
  },
  {
    title: "Privacy Policy",
    name: "privacy_policy",
    path: "/privacy_policy",
    render: () => <PrivacyPolicy />,
  },
  {
    title: "FAQ",
    name: "faq",
    path: "/faq",
    render: () => <Faq />,
  },
  {
    title: "About us",
    name: "about_us",
    path: "/about_us",
    render: () => <Faq />,
  },
];
export default routes;
