import { Item, InventoryResponse } from "items/types";
import { useEffect, useState } from "react";
import { Acquired } from "./Acquired";
import { Market } from "./Market";
import { Mastery } from "./Mastery";
import { Wiki } from "./Wiki";
import { Wishlist } from "./Wishlist";

interface ComponentProps {
  data: Item;
}

const BTN_CLASSES =
  "cursor-pointer inline-flex w-[65px] py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-zinc-800 focus:z-10 focus:ring-4 focus:ring-gray-200";
export const Links = ({ data }: ComponentProps) => {
  const [localData, setLocalData] = useState<Item>(data);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const handleResponse = (response: Partial<InventoryResponse>) => {
    setLocalData((value) => {
      return { ...value, ...response };
    });
  };

  return (
    <div className="flex">
      <Wiki data={data} className={BTN_CLASSES} showLabel={true} />
      <Market data={data} className={BTN_CLASSES} showLabel={true} />
      <Mastery
        data={data}
        className={BTN_CLASSES}
        handleResponse={handleResponse}
        showLabel={true}
      />
      <Wishlist
        data={localData}
        className={BTN_CLASSES}
        handleResponse={handleResponse}
        showLabel={true}
      />
      <Acquired
        data={localData}
        className={BTN_CLASSES}
        handleResponse={handleResponse}
        showLabel={true}
      />
    </div>
  );
};
