import { RouteDefinition } from "../common/router";
import { ItemPage } from "./pages/ItemPage";

const routes: RouteDefinition[] = [
  {
    title: "",
    name: "item",
    path: "/item/?id&name",
    render: ({ id, name }) => {
      return <ItemPage itemId={id} name={name} />;
    },
  },
];
export default routes;
