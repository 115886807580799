import { API, Auth } from "aws-amplify";
import { CognitoUserSession } from "amazon-cognito-identity-js";

class Client {
  userSession: CognitoUserSession | undefined;

  async getToken() {
    if (
      this.userSession === undefined ||
      (this.userSession && !this.userSession.isValid())
    ) {
      this.userSession = await Auth.currentSession().catch((ex) => {
        if (ex === "No current user") {
          return undefined;
        }
        throw ex;
      });
      if (this.userSession === undefined) {
        return null;
      }
    }

    return this.userSession.getIdToken().getJwtToken();
  }

  async get(
    endpoint: string,
    params: { [key: string]: string | number | string[] }
  ) {
    const init = {
      headers: {
        Authorization: await this.getToken(),
      },
      cors: true,
      response: true,
      queryStringParameters: params,
    };
    return API.get("ApiGatewayRestApi", endpoint, init);
  }

  async put(endpoint: string, data: any) {
    const init = {
      body: data,
      headers: {
        Authorization: await this.getToken(),
      },
    };
    return API.put("ApiGatewayRestApi", endpoint, init);
  }
}

export const client = new Client();
