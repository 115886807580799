import { ReactNode } from "react";
import { Item } from "items/types";
import { Polarity } from "./Polarity";
import { capitalizeFirst } from "common/utils";

interface ComponentProps {
  data: Item;
}

const defaultConverter = (value: NonNullable<Item[keyof Item]>) => {
  return value.toString();
};

export const MainStats = ({ data }: ComponentProps) => {
  const renderStat = <T extends keyof Item>(
    stat: T,
    label: string,
    valueConverter: (
      value: NonNullable<Item[T]>
    ) => ReactNode = defaultConverter
  ) => {
    let value = data[stat];
    if (value === undefined) {
      return null;
    }

    const convertedValue = valueConverter(value);
    if (convertedValue == null || convertedValue === "") {
      return null;
    }

    return (
      <div className="grid grid-cols-2 border rounded-xl p-2">
        <div className="border-r pr-2 mr-2 font-medium">{label}</div>
        <div>{convertedValue}</div>
      </div>
    );
  };

  return (
    <div className="">
      <div className="text-lg font-bold">Stats</div>
      <div className="grid grid-cols-2 xl:grid-cols-3 gap-2">
        {renderStat("health", "Health")}
        {renderStat("shield", "Shield")}
        {renderStat("armor", "Armor")}
        {renderStat("power", "Power")}
        {renderStat("sprint", "Sprint")}
        {renderStat("stamina", "Stamina")}
        {renderStat("masteryReq", "Mastery requirement")}
        {renderStat("baseDrain", "Base drain")}
        {renderStat("fusionLimit", "Fusion limit")}
        {renderStat("isPrime", "Prime", (value) => (value ? "Yes" : "No"))}
        {renderStat("isAugment", "Augment", (value) => (value ? "Yes" : "No"))}
        {renderStat("rarity", "Rarity")}
        {renderStat("accuracy", "Accuracy")}
        {renderStat("polarity", "Polarity", (value) => (
          <Polarity polarity={value} />
        ))}
        {renderStat(
          "procChance",
          "Status chance",
          (value) => (value * 100.0).toFixed(2) + "%"
        )}
        {renderStat("type", "Type")}
        {renderStat("trigger", "Trigger")}
        {renderStat("multishot", "Multishot")}
        {renderStat("chargeAttack", "Charge attack")}
        {renderStat("fireRate", "Fire rate", (value) => value.toFixed(2))}
        {renderStat("projectile", "Projectile")}
        {renderStat("criticalChance", "Critical chance", (value) => {
          return (value * 100).toFixed(2) + "%";
        })}
        {renderStat("criticalMultiplier", "Critical multiplier", (value) => {
          return value + "x";
        })}
        {renderStat("leapAttack", "Leap attack")}
        {renderStat("reloadTime", "Reload time", (value) => {
          return value + "s";
        })}
        {renderStat("magazineSize", "Magazine size")}
        {renderStat("secondsPerShot", "Seconds per shot")}
        {renderStat("damage", "Damage", (value) => {
          const result: ReactNode[] = [];
          const keys = Object.keys(value) as (keyof typeof value)[];
          keys.forEach((damageType) => {
            const damageValue = value[damageType];
            if (damageValue === 0) {
              return;
            }
            const label = capitalizeFirst(damageType);

            return result.push(
              <p key={damageType}>{`${label}: ${damageValue.toFixed(2)}`}</p>
            );
          });
          return result;
        })}
        {renderStat("spinAttack", "Spin attack")}
        {renderStat("damagePerSecond", "Damage per second")}
        {renderStat("noise", "Noise")}
        {renderStat("wallAttack", "Wall attack")}
        {renderStat("polarities", "Polarities", (value) => {
          return value.map((polarity, index) => {
            return <Polarity polarity={polarity} key={index} />;
          });
        })}
        {renderStat("omegaAttenuation", "Riven disposition")}
        {renderStat("totalDamage", "Total damage")}
        {renderStat("damageTypes", "Damage types", (value) => {
          const result: string[] = [];
          Object.keys(value).forEach((damage) => {
            return result.push(`${capitalizeFirst(damage)}: ${value[damage]}`);
          });
          return result.join(", ");
        })}
        {renderStat("ducats", "Ducats")}
        {renderStat("minEnemyLevel", "Minimum enemy level")}
        {renderStat("maxEnemyLevel", "Maximum enemy level")}
        {renderStat("blockingAngle", "Blocking angle")}
        {renderStat("comboDuration", "Combo duration")}
        {renderStat("followThrough", "Follow through")}
        {renderStat("heavyAttackDamage", "Heavy attack damage")}
        {renderStat("heavySlamAttack", "Heavy slam attack damage")}
        {renderStat("heavySlamRadialDamage", "Heavy slam radial damage")}
        {renderStat("heavySlamRadius", "Heavy slam radius")}
        {renderStat("range", "Range")}
        {renderStat("slamAttack", "Slam attack")}
        {renderStat("slamRadialDamage", "Slam radial damage")}
        {renderStat("slamRadius", "Slam radius")}
        {renderStat("slideAttack", "Slide attack")}
        {renderStat("stancePolarity", "Stance polarity", (value) => (
          <Polarity polarity={value} />
        ))}
        {renderStat("windUp", "Wind up")}
        {renderStat("numUpgradesInSet", "Number of upgrades")}
        {renderStat("vaulted", "Vaulted", (value) => {
          if (value) {
            return "Yes";
          }
          return "No";
        })}
        {renderStat("systemName", "Planet")}
      </div>
    </div>
  );
};
