import { Menu } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { useRouter } from "react-router5";

import { ReactComponent as LogoFull } from "../images/logo_full.svg";
import { ReactComponent as LogoIcon } from "../images/logo_icon.svg";
import { UserMenu } from "./UserMenu";
import { SearchBox } from "./SearchBox";
import { MasteredIcon } from "items/images/MasteredIcon";
import { WishlistIcon } from "items/images/WishlistIcon";
import { AcquiredIcon } from "items/images/AcquiredIcon";
import { AdvancedSearchIcon } from "advancedSearch/images/AdvancedSearchIcon";
import { useAuthenticated } from "auth/hooks";
import { Logout } from "./Logout";

const BTN_CLASSES =
  "inline-flex py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-zinc-800 focus:z-10 focus:ring-4 focus:ring-gray-200";
export const NavBar = () => {
  const router = useRouter();
  const user = useAuthenticated();

  let auth_btn_classes = BTN_CLASSES.replace(
    "hover:bg-gray-100",
    "hover:bg-red-500"
  ).replace("hover:text-zinc-800", "hover:text-white");

  if (!user) {
    auth_btn_classes = auth_btn_classes.replace(
      "hover:bg-red-500",
      "hover:bg-zinc-500"
    );
  }

  return (
    <div className="fixed w-full bg-white border-b-[1px] border-black text-black shadow-xl h-[60px] lg:h-[80px] z-10">
      {/* desktop */}
      <div className="hidden lg:flex items-center justify-between px-4 py-2 h-full lg:justify-start">
        <div className="flex justify-start max-w-[300px] h-full lg:w-0 lg:flex-1 mr-8">
          <button
            className="w-full h-full"
            onClick={() => router.navigate("root")}
          >
            <LogoFull />
          </button>
        </div>
        <div className="flex-grow max-w-[200px] xl:max-w-[300px]">
          <SearchBox autoFocus={true} />
        </div>
        <button
          onClick={() => router.navigate("advanced_search")}
          className={BTN_CLASSES + " ml-2"}
          title="Go to the advanced search page"
        >
          <AdvancedSearchIcon className="w-5 h-5 xl:mr-2" />
          <div className="hidden xl:block">Advanced Search</div>
        </button>
        <div className="hidden items-center justify-end lg:flex lg:flex-1 w-100">
          {user && (
            <>
              <button
                onClick={() =>
                  router.navigate("advanced_search", { mastered: "true" })
                }
                className={BTN_CLASSES}
                title="Go to your mastered items list"
              >
                <MasteredIcon className="w-5 h-5 2xl:mr-2" />
                <div className="hidden 2xl:block">Mastered</div>
              </button>
              <button
                onClick={() =>
                  router.navigate("advanced_search", { wishlisted: "true" })
                }
                className={BTN_CLASSES}
                title="Go to your wishlisted items list"
              >
                <WishlistIcon className="w-5 h-5 2xl:mr-2" />
                <div className="hidden 2xl:block">Wishlist</div>
              </button>
              <button
                onClick={() =>
                  router.navigate("advanced_search", { acquired: "true" })
                }
                className={BTN_CLASSES}
                title="Go to your acquired items list"
              >
                <AcquiredIcon className="w-5 h-5 2xl:mr-2" />
                <div className="hidden 2xl:block">Acquired</div>
              </button>
              <UserMenu className={BTN_CLASSES + " max-w-[200px]"} />
            </>
          )}
          <Logout className={auth_btn_classes} />
        </div>
      </div>

      {/* Mobile */}
      <div className="flex lg:hidden items-center justify-start h-15 p-2">
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button>
            <Bars3Icon className="h-5 w-5 mx-2" />
          </Menu.Button>
          <Menu.Items className="z-10 absolute left-0 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              {user && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-primary text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() =>
                        router.navigate("advanced_search", { mastered: "true" })
                      }
                      title="Go to your mastered items list"
                    >
                      <MasteredIcon className="w-5 h-5 mr-1" />
                      Mastered
                    </button>
                  )}
                </Menu.Item>
              )}
              {user && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-primary text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() =>
                        router.navigate("advanced_search", {
                          wishlisted: "true",
                        })
                      }
                      title="Go to your wishlisted items list"
                    >
                      <WishlistIcon className="w-5 h-5 mr-1" />
                      Wishlist
                    </button>
                  )}
                </Menu.Item>
              )}
              {user && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-primary text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() =>
                        router.navigate("advanced_search", {
                          acquired: "true",
                        })
                      }
                      title="Go to your acquired items list"
                    >
                      <AcquiredIcon className="w-5 h-5 mr-1" />
                      Acquired
                    </button>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-primary text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => router.navigate("advanced_search")}
                    title="Go to the advanced search page"
                  >
                    <AdvancedSearchIcon className="w-5 h-5 mr-1" />
                    Advanced search
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div>
                    <UserMenu
                      className={`${
                        active ? "bg-primary text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    />
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div>
                    <Logout
                      label="Logout"
                      className={`${
                        active ? "bg-red-500 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    />
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>

        <div className="flex justify-start lg:w-0 lg:flex-1 mx-2">
          <button onClick={() => router.navigate("root")}>
            <div className="hidden sm:block w-[300px]">
              <LogoFull />
            </div>
            <div className="block sm:hidden w-[50px]">
              <LogoIcon />
            </div>
          </button>
        </div>
        <div className="flex-grow flex justify-end">
          <SearchBox />
        </div>
      </div>
    </div>
  );
};
