import { useMemo } from "react";
import { toast } from "react-toastify";

import { Component } from "items/types";
import { Spinner } from "common/components/Spinner";
import { inventoryUpdate } from "items/utils";
import { AcquiredIcon } from "items/images/AcquiredIcon";
import { useAuthenticated } from "auth/hooks";

interface ComponentProps {
  data: Component[];
  setData: React.Dispatch<React.SetStateAction<Component[]>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BulkAcquire = ({
  data,
  setData,
  loading,
  setLoading,
}: ComponentProps) => {
  const user = useAuthenticated();

  const allAcquired = useMemo(() => {
    return !data.some((value) => !value.acquired);
  }, [data]);

  const handleClick = async () => {
    if (!user) {
      toast(`Please sign in to mark items as acquired.`, {
        type: "error",
      });
      return;
    }

    setLoading(true);
    const currentAllAcquired = allAcquired;
    const result = await Promise.all(
      data.map((component) => {
        if (component.acquired === currentAllAcquired) {
          return inventoryUpdate(component.fgId, {
            acquired: !currentAllAcquired,
            wishlisted:
              !component.acquired && component.wishlisted
                ? false
                : component.wishlisted!,
          });
        }
        return Promise.resolve(component);
      })
    );
    if (currentAllAcquired) {
      toast("Components unmarked as acquired", {
        type: "success",
      });
    } else {
      toast("Components marked as acquired", {
        type: "success",
      });
    }
    setLoading(false);
    const newData = [...data];
    result.forEach((component, index) => {
      newData[index].acquired = component.acquired;
      newData[index].wishlisted = component.wishlisted;
    });
    setData(newData);
  };

  if (loading) {
    return (
      <div className="w-6 h-6">
        <Spinner />
      </div>
    );
  }

  let title = `${
    allAcquired ? "Unmark all components " : "Mark all components "
  } as acquired`;
  let opacity = allAcquired ? "1" : "0.5";

  return (
    <button title={title} onClick={handleClick}>
      <AcquiredIcon opacity={opacity} />
    </button>
  );
};
