const HEADING_CLASSNAME = "font-medium text-lg";
export const PrivacyPolicy = () => {
  return (
    <div className="grid grid-cols-1 gap-4">
      <p>
        This privacy policy will explain how FrameGrinder uses the personal data
        we collect from you when you use our website.
      </p>

      <div>
        <h1 className={HEADING_CLASSNAME}>Topics:</h1>
        <ul className="list-disc">
          <li>What data do we collect?</li>
          <li>How do we collect your data?</li>
          <li>How will we use your data?</li>
          <li>How do we store your data?</li>
          <li>Marketing</li>
          <li>What are your data protection rights?</li>
          <li>What are cookies?</li>
          <li>How do we use cookies?</li>
          <li>What types of cookies do we use?</li>
          <li>How to manage your cookies?</li>
          <li>Privacy policies of other websites</li>
          <li>Changes to our privacy policy</li>
          <li>How to contact us</li>
        </ul>
      </div>

      <div>
        <h1 className={HEADING_CLASSNAME}>What data do we collect?</h1>
        <p>Framegrinder collects the following data:</p>
        <ul className="list-disc">
          <li>Username</li>
          <li>E-mail address</li>
          <li>Mastered, wishlisted and/or acquired status for items</li>
        </ul>
      </div>

      <div>
        <h1 className={HEADING_CLASSNAME}>How do we collect data?</h1>
        <p>
          You directly provide FrameGrinder with the data we collect. We collect
          data and process data when you:
        </p>
        <ul className="list-disc">
          <li>Register for an account</li>
          <li>Mark an item as mastered, wishlisted and/or acquired</li>
        </ul>
      </div>

      <div>
        <h1 className={HEADING_CLASSNAME}>How will we use your data?</h1>
        <ul className="list-disc">
          <li>
            We will use your data to contact you when requesting a new password
            or changing your password.
          </li>
          <li>
            Data will also be used to display mastered, wishlisted and/or
            acquired statusses of items to you.
          </li>
        </ul>
      </div>

      <div>
        <h1 className={HEADING_CLASSNAME}>How do we store your data?</h1>
        <p>Our data is stored at the Amazon AWS EU Frankfurt Region.</p>
      </div>

      <div>
        <h1 className={HEADING_CLASSNAME}>Marketing</h1>
        <p>FrameGrinder will not use your data for any marketing purposes.</p>
      </div>

      <div className="grid grid-cols-1 gap-2">
        <h1 className={HEADING_CLASSNAME}>
          What are your data protection rights?
        </h1>
        <p>
          FrameGrinder would like to make sure you are fully aware of all of
          your data proteciton rights. Every user is entitled to the following:
        </p>
        <ul className="list-disc">
          <li>
            The right to access - You have the right to request FrameGrinder for
            copies of your personal data. We may charge you a small fee for this
            service.
          </li>
          <li>
            The right to rectification - You have the right to request that
            FrameGrinder will correct any information you believe is inaccurate.
            You also have the right to request FrameGrinder to complete
            information you believe is incomplete.
          </li>
          <li>
            The right to erasure - You have the right to request that
            FrameGrinder erase your personal data, under certain conditions.
          </li>
          <li>
            The right to restrict processing - You have the right to request
            that FrameGrinder restrict the processing of your personal data,
            under certain conditions.
          </li>
          <li>
            The right to object to processing - You have the right to object to
            FrameGrinder's processing of your personal data, under certain
            conditions.
          </li>
          <li>
            The right to data portability - You have the right to request that
            FrameGrinder transfer the data that we have collected to another
            organization, or directly to you, under certain conditions.
          </li>
        </ul>
        <p>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us at our
          email: info@framegrinder.app
        </p>
      </div>

      <div>
        <h1 className={HEADING_CLASSNAME}>What are cookies?</h1>
        <p>
          Cookies are text files places on your computer to collect standard
          internet log information and visitor behavior information. When you
          visit our website, we may collect information from you automatically
          through cookies or similar technology. For further information visit:{" "}
          <a
            href="https://allaboutcookies.org/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            https://allaboutcookies.org/
          </a>
        </p>
      </div>

      <div>
        <h1 className={HEADING_CLASSNAME}>How do we use cookies?</h1>
        <ul className="list-disc">
          <li>Keeping you signed in</li>
        </ul>
      </div>

      <div>
        <h1 className={HEADING_CLASSNAME}>What types of cookies do we use?</h1>
        <h2 className={HEADING_CLASSNAME}>Functionality</h2>
        <ul className="list-disc mb-2">
          <li>
            FrameGrinder uses these cookies so that we recognize you on our
            website and will keep you logged in.
          </li>
        </ul>
      </div>

      <div>
        <h1 className={HEADING_CLASSNAME}>How to manage cookies?</h1>
        <p>
          You can set your browser to not accept cookies, and the website{" "}
          <a
            href="https://allaboutcookies.org/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            https://allaboutcookies.org/
          </a>{" "}
          will tell you how to remove cookies from your browser. However, in a
          few cases, some of our website features may not function as a result.
        </p>
      </div>

      <div>
        <h1 className={HEADING_CLASSNAME}>
          Privacy policies of other websites
        </h1>
        <p>
          FrameGrinders website contains links to other websites. Our privacy
          policy applies only to our website, so if you click on a link to
          another website, you should read their privacy policy.
        </p>
      </div>

      <div>
        <h1 className={HEADING_CLASSNAME}>Changes to our privacy policy</h1>
        <p>
          FrameGrinder keeps its privacy policy under regular review and places
          any updates on this web page. This privacy policy was last updated on
          30 October 2022.
        </p>
      </div>

      <div>
        <h1 className={HEADING_CLASSNAME}>How to contact us</h1>
        <p>
          If you have any questions about FrameGrinder's privacy policy, the
          data we hold on you, or you would like to exercise one of your data
          protection rights, please do not hesitate to contact us. Email us at:
          info@framegrinder.app
        </p>
      </div>
    </div>
  );
};
