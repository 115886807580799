import { ReactNode } from "react";
import createRouter, { State, Route } from "router5";
import { useRoute as originalUseRoute } from "react-router5";
import browserPlugin from "router5-plugin-browser";
import { RouteContext } from "react-router5/dist/types";

import rootRoutes from "../routes";
import authRoutes from "../auth/routes";
import itemRoutes from "../items/routes";
import advancedSearchRoutes from "../advancedSearch/routes";

function configureRouter() {
  const routes: any[] = [
    ...rootRoutes,
    ...authRoutes,
    ...itemRoutes,
    ...advancedSearchRoutes,
  ];

  const router = createRouter(routes, {
    defaultRoute: "root",
  });

  router.usePlugin(browserPlugin());
  router.useMiddleware(routeComponentMiddleware(routes));
  router.useMiddleware(routeTitleMiddleware(routes));

  router.start();
  return router;
}

const routeTitleMiddleware =
  (routes: any) =>
  (router: typeof Router, dependencies: any) =>
  (toState: State, fromState: State) => {
    const activeRoute = routes.find((r: any) => r.name === toState.name);
    let title = "ROUTE HAS NO TITLE";
    if (activeRoute.title !== undefined) {
      title = activeRoute.title;
    }
    return Promise.resolve({ ...toState, title });
  };

const routeComponentMiddleware =
  (routes: any) =>
  (router: typeof Router, dependencies: any) =>
  (toState: State, fromState: State) => {
    const activeRoute = routes.find((r: any) => r.name === toState.name);

    return Promise.resolve({
      ...toState,
      render: () => activeRoute.render(toState.params),
    });
  };

export interface RouteDefinition extends Route {
  render: ({}: { [key: string]: any }) => ReactNode; // eslint-disable-line no-empty-pattern
  title: string;
}
export interface FrameGrinderState extends State {
  render: () => ReactNode;
  title: string;
}

interface FrameGrinderRouteContext extends RouteContext {
  route: FrameGrinderState;
}

export const useRoute = () => {
  return originalUseRoute() as FrameGrinderRouteContext;
};

export const Router = configureRouter();
