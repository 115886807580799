interface ComponentProps {
  data: string[];
}

const regex = new RegExp(/\S*\d+\S*/, "ig");

export const LevelStats = ({ data }: ComponentProps) => {
  return (
    <div className="rounded-lg border p-2">
      <div className="text-lg font-bold">Levels</div>
      <table className="w-full">
        <thead>
          <tr className="border-b-2">
            <td>Level</td>
            <td>Stat</td>
          </tr>
        </thead>
        <tbody>
          {data.map((stat, index) => {
            return (
              <tr className="border-b-2" key={index}>
                <td>{index}</td>
                <td>
                  <div className="py-2">
                    <p className="pb-1" key={index}>
                      {stat
                        .replace(":", ": ")
                        .split(" ")
                        .map((word, wordIndex) =>
                          word.match(regex) ? (
                            <span
                              className="font-bold"
                              key={`${index}-${wordIndex}-${word}`}
                            >
                              {word}{" "}
                            </span>
                          ) : (
                            word + " "
                          )
                        )}
                    </p>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
