import { useRouter } from "react-router5";
import { ReactComponent as LogoIcon } from "../images/logo_icon.svg";

export const Footer = () => {
  const router = useRouter();
  return (
    <div className="bottom-2 w-full h-[400px] md:h-[200px] flex justify-center pt-2">
      <div className="flex justify-center items-center w-11/12 h-[375px] md:h-[175px] border bg-white rounded-xl shadow-xl grid grid-cols-1 md:grid-cols-3 justify-items-center gap-6">
        <LogoIcon className="w-full h-full max-w-[100px] pt-2" />
        <div></div>
        <div className="grid grid-cols-1">
          <ul>
            <a
              href="https://discord.gg/DdZA6gcrew"
              target="_blank"
              rel="noreferrer"
            >
              Discord
            </a>
            <li>
              <button onClick={() => router.navigate("faq")}>FAQ</button>
            </li>
            <li>
              <button onClick={() => router.navigate("privacy_policy")}>
                Privacy policy
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
