import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router5";
import { Authenticator } from "@aws-amplify/ui-react";
import { Helmet } from "react-helmet";
import { defaultTitle } from "helmet";

import { Router } from "./common/router";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <RouterProvider router={Router}>
    <Helmet>
      <title>{defaultTitle}</title>
    </Helmet>
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  </RouterProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
