import { ChangePassword } from "./pages/ChangePassword";
import { Login } from "./pages/Login";
import { Profile } from "./pages/Profile";
import { RouteDefinition } from "common/router";
import { Auth } from "aws-amplify";

const routes: RouteDefinition[] = [
  {
    title: "",
    name: "login",
    path: "/login",
    render: () => <Login />,
  },
  {
    title: "Change password",
    name: "change_password",
    path: "/change_password",
    render: () => <ChangePassword />,
    canActivate: () => {
      return () => {
        return Auth.currentAuthenticatedUser();
      };
    },
  },
  {
    title: "Profile",
    name: "profile",
    path: "/profile",
    render: () => <Profile />,
    canActivate: () => {
      return () => {
        return Auth.currentAuthenticatedUser();
      };
    },
  },
];
export default routes;
