import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { client } from "common/client";
import { Component, InventoryResponse, Item } from "items/types";
import { Spinner } from "common/components/Spinner";
import { MasteredIcon } from "items/images/MasteredIcon";
import { useAuthenticated } from "auth/hooks";

const MASTERABLE_CATEGORIES = [
  "archwing",
  "arch-melee",
  "arch-gun",
  "melee",
  "primary",
  "secondary",
  "sentinels",
  "warframes",
];

interface ComponentProps {
  data: Item | Component;
  className?: string;
  handleResponse?: (value: Partial<InventoryResponse>) => void;
  showLabel?: boolean;
}

export const Mastery = ({
  data,
  className,
  handleResponse,
  showLabel,
}: ComponentProps) => {
  const [mastered, setMastered] = useState(data.mastered);
  const [loading, setLoading] = useState(false);
  const user = useAuthenticated();

  const masterable = MASTERABLE_CATEGORIES.includes(
    data.category.toLowerCase()
  );

  useEffect(() => {
    setMastered(data.mastered);
  }, [data]);

  const handleClick = async () => {
    if (!masterable || loading) {
      return;
    }
    if (!user) {
      toast(`Please sign in to mark ${data.name} as mastered.`, {
        type: "error",
      });
      return;
    }
    setLoading(true);
    const newMastered = !mastered;
    const response = await client
      .put("/api/inventory", { fgId: data.fgId, mastered: newMastered })
      .finally(() => setLoading(false));
    if (handleResponse) {
      handleResponse({ mastered: response.mastered, fgId: response.fgId });
    }
    setMastered(newMastered);
    if (newMastered) {
      toast(`${data.name} marked as mastered`, {
        type: "success",
      });
    } else {
      toast(`${data.name} unmarked as mastered`, {
        type: "success",
      });
    }
  };

  let title = `${mastered ? "Unmark" : "Mark"} as mastered`;
  let opacity = mastered ? "1" : "0.5";
  if (!masterable) {
    title = "Not masterable";
    opacity = "0.5";
  }

  let icon = <MasteredIcon opacity={opacity} />;
  if (loading) {
    icon = <Spinner />;
  }

  if (showLabel) {
    className += " xl:w-[130px]";
  }

  return (
    <button
      title={title}
      onClick={handleClick}
      className={className || "w-6 h-6"}
      style={{ opacity: opacity }}
    >
      {icon}
      {showLabel && (
        <span className="ml-2 mt-0.5 hidden xl:block">Mastered</span>
      )}
    </button>
  );
};
