import { AdvancedSearchPage } from "advancedSearch/pages/AdvancedSearchPage";
import { useAuthenticated } from "auth/hooks";
import { useRouter } from "react-router5";

const BTN_CLASSES =
  "inline-flex py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-zinc-800 focus:z-10 focus:ring-4 focus:ring-gray-200";
export const Profile = () => {
  const user = useAuthenticated();
  const router = useRouter();

  return (
    <>
      <div className="grid grid-cols-2 w-full">
        <h1 className="text-2xl">Hello {user?.attributes?.email}</h1>
        <button className={BTN_CLASSES + " justify-self-end"} onClick={() => router.navigate("change_password")}>
          Change Password
        </button>
      </div>
      <p className="py-6">Get to work on crossing off items on your wishlist!</p>
      <AdvancedSearchPage wishlisted={true} noResultText="It looks like your wishlist is empty!" hideSearch />
    </>
  );
};
