import { useState, ReactNode } from "react";
import { Dialog as OriginalDialog } from "@headlessui/react";

interface ComponentProps {
  title: string;
  content: ReactNode;
  buttonContent: ReactNode;
}

export const Dialog = ({ title, content, buttonContent }: ComponentProps) => {
  let [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button onClick={() => setIsOpen(true)}>{buttonContent}</button>
      {isOpen && (
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <OriginalDialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4">
                <OriginalDialog.Panel className="w-full min-w-[80%] max-w-sm rounded bg-white p-4">
                  <OriginalDialog.Title className="border-b-2 font-bold">
                    {title}
                  </OriginalDialog.Title>
                  {content}

                  <button
                    className="py-2.5 px-6 rounded-full border border-gray-200 focus:outline-none hover:bg-gray-100 hover:text-zinc-800"
                    onClick={() => setIsOpen(false)}
                  >
                    Ok
                  </button>
                </OriginalDialog.Panel>
              </div>
            </div>
          </OriginalDialog>
        </div>
      )}
    </>
  );
};
