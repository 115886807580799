import { useState, useEffect, useMemo, useCallback } from "react";
import { useRouter } from "react-router5";
import { ColumnDef, PaginationState } from "@tanstack/react-table";

import { client } from "common/client";
import debounce from "lodash.debounce";
import { SearchInput } from "advancedSearch/components/SearchInput";
import { CATEGORIES, Category, CategorySwitch } from "advancedSearch/components/CategorySwitch";
import { capitalizeFirst } from "common/utils";
import LoadImage from "common/components/ImageLoader";
import { Item } from "items/types";
import { CheckboxFilter } from "advancedSearch/components/CheckboxFilter";
import { Table } from "common/components/Table";
import { useAuthenticated } from "auth/hooks";
import { AdvancedSearchRowLinks } from "advancedSearch/components/AdvancedSearchRowLinks";

const renderName = (item: Item) => {
  let result = item.name;
  if (item.systemName != null) {
    result += ` (${item.systemName})`
  }
  return result
}

const columns: ColumnDef<Item>[] = [
  {
    id: "image",
    header: "",
    accessorKey: "imageName",
    cell: ({ getValue }) => {
      const value = getValue();
      return (
        <div className="h-[60px] my-2">
          <LoadImage src={value ? `https://cdn.warframestat.us/img/${value}` : undefined} />
        </div>
      );
    },
    size: 60,
    minSize: 60,
    maxSize: 60,
  },
  {
    id: "name",
    header: "Name",
    accessorKey: "name",
    size: 200,
    minSize: 200,
    maxSize: 200,
    cell: ({ row }) => {
      return renderName(row.original)
    },
  },
  {
    id: "category",
    header: "Category",
    accessorKey: "category",
    size: 100,
    minSize: 100,
    maxSize: 100,
    meta: {
      hide: "sm",
    },
  },
  {
    id: "description",
    header: "Description",
    accessorKey: "description",
    minSize: 200,
    maxSize: Number.MAX_SAFE_INTEGER,
    meta: {
      hide: "md",
    },
  },
  {
    id: "actions",
    accessorKey: "name",
    header: "",
    size: 80,
    minSize: 80,
    maxSize: 80,
    meta: {
      ignoreRowClick: true,
    },
    cell: ({ row }) => {
      return <AdvancedSearchRowLinks row={row.original} />;
    },
  },
];

interface ComponentProps {
  category?: Category[];
  mastered?: boolean;
  wishlisted?: boolean;
  acquired?: boolean;
  name?: string;
  hideSearch?: boolean;
  page?: number;
  limit?: number;
  noResultText?: string;
}

export const AdvancedSearchPage = ({
  category,
  mastered,
  wishlisted,
  acquired,
  name,
  hideSearch,
  page,
  limit,
  noResultText,
}: ComponentProps) => {
  const user = useAuthenticated();
  const router = useRouter();

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<Item[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [{ pageIndex, pageSize }, setLocalPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [masteredFilter, setMasteredFilter] = useState<boolean | null>(null);
  const [wishlistedFilter, setWishlistedFilter] = useState<boolean | null>(null);
  const [acquiredFilter, setAcquiredFilter] = useState<boolean | null>(null);
  const [showNoResult, setShowNoResult] = useState(false);

  const handleSearch = useCallback(
    async (
      page: number = 0,
      pageSize: number = 10,
      name?: string,
      categories?: Category[],
      mastered?: boolean,
      wishlisted?: boolean,
      acquired?: boolean
    ) => {
      let query: { [key: string]: string | string[] | number } = {
        ...(name ? { name } : {}),
        ...(mastered !== undefined ? { mastered: mastered.toString() } : {}),
        ...(wishlisted !== undefined ? { wishlisted: wishlisted.toString() } : {}),
        ...(acquired !== undefined ? { acquired: acquired.toString() } : {}),
      };
      if (categories && categories.length > 0) {
        query["category"] = categories.map((cat) => capitalizeFirst(cat));
      }

      if (Object.keys(query).length === 0) {
        setResult([]);
        setTotalRows(0);
        setShowNoResult(false);
        setLoading(false);
        return;
      }

      query = {
        ...query,
        offset: Math.max(page * pageSize, 0),
        limit: pageSize,
        skipComponents: "true",
      };
       


      let endpoint = "/api/items/all";
      if (user != null) {
        endpoint = "/api/user-items/all";
      }

      setLoading(true);
      const result = await client.get(endpoint, query);
      setTotalRows(parseInt(result.headers["x-result-count"]));
      setResult(result.data);
      setShowNoResult(true);
      setLoading(false);
    },
    [user]
  );

  const setQuery = useCallback(
    (newState: { [key: string]: string | string[] | number | undefined }) => {
      const currentQuery = router.getState().params;

      let query: { [key: string]: string | string[] | number } = {
        ...currentQuery,
        ...newState,
      };

      router.navigate("advanced_search", query);
    },
    [router]
  );

  const debouncedSetQuery = useMemo(() => {
    return debounce((newState) => setQuery(newState), 500);
  }, [setQuery]);

  const setPagination = useCallback(
    (newValue: any, setQuery = false) => {
      setLocalPagination((old) => {
        // newValue initially is a dict, later becomes a function provided by the table.
        let newVal = newValue;
        if (typeof newVal === "function") {
          newVal = newVal(old);
        }
        if (setQuery) {
          debouncedSetQuery({
            page: newVal.pageIndex + 1,
            limit: newVal.pageSize,
          });
        }
        return newVal;
      });
    },
    [debouncedSetQuery]
  );

  useEffect(() => {
    if (user) {
      setMasteredFilter(mastered ?? null);
      setAcquiredFilter(acquired ?? null);
      setWishlistedFilter(wishlisted ?? null);
    }

    const _page = page != null ? Math.max(page, 0): 0;
    const _limit = limit != null ? Math.min(Math.max(limit, 10), 50): 10;
    setSelectedCategories(category ?? []);
    setPagination({ pageIndex: _page, pageSize: _limit});
    handleSearch(
      _page,
      _limit,
      name,
      category,
      user ? mastered : undefined,
      user ? wishlisted : undefined,
      user ? acquired : undefined
    );
  }, [name, category, handleSearch, mastered, wishlisted, acquired, user, page, limit, setPagination]);

  const handleCategoryChange = (category: Category, value: boolean) => {
    let newValue = [...selectedCategories];
    if (value) {
      newValue = [...newValue, category];
    } else {
      newValue = newValue.filter((value) => value !== category);
    }
    setSelectedCategories(newValue);
    debouncedSetQuery({ category: newValue, page: 1, limit: 10 });
  };

  const renderCategories = () => {
    return CATEGORIES.map((category, index) => {
      return (
        <CategorySwitch
          key={index}
          category={category}
          handleChange={(value: boolean) => {
            handleCategoryChange(category, value);
          }}
          enabled={selectedCategories.includes(category)}
        />
      );
    });
  };

  return (
    <>
      {!hideSearch && (
        <div className="pb-4 relative">
          <div className="grid grid-cols-1 rounded-lg border p-2 w-full">
            <div className="flex justify-center w-full">
              <div className="relative w-4/5 md:w-3/5 xl:w-2/4">
                <SearchInput
                  handleSearch={(value) => {
                    setPagination({ pageIndex: 0, pageSize: pageSize });
                    debouncedSetQuery({
                      name: value !== "" ? value : undefined,
                      page: 1,
                      limit: pageSize,
                    });
                  }}
                  initialSearchValue={name ?? ""}
                  loading={loading}
                />
              </div>
            </div>
            <div className="text-bold text-lg">Categories</div>
            <div className="mt-2 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4">{renderCategories()}</div>
            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
              <div>
                <div className="mt-2 text-bold text-lg">Mastered</div>
                <div className="mt-2">
                  <CheckboxFilter
                    value={masteredFilter}
                    setter={(value) => {
                      setPagination({ pageIndex: 0, pageSize: pageSize });
                      setMasteredFilter(value);
                      debouncedSetQuery({
                        mastered: value != null ? value.toString() : undefined,
                        page: 1,
                        limit: pageSize,
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="mt-2 text-bold text-lg">Wishlisted</div>
                <div className="mt-2">
                  <CheckboxFilter
                    value={wishlistedFilter}
                    setter={(value) => {
                      setPagination({ pageIndex: 0, pageSize: pageSize });
                      setWishlistedFilter(value);
                      debouncedSetQuery({
                        wishlisted: value != null ? value.toString() : undefined,
                        page: 1,
                        limit: pageSize,
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="mt-2 text-bold text-lg">Acquired</div>
                <div className="mt-2">
                  <CheckboxFilter
                    value={acquiredFilter}
                    setter={(value) => {
                      setPagination({ pageIndex: 0, pageSize: pageSize });
                      setAcquiredFilter(value);
                      debouncedSetQuery({
                        acquired: value != null ? value.toString() : undefined,
                        page: 1,
                        limit: pageSize,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Table<Item>
        data={result}
        columns={columns}
        handleRowClick={(row) => {
          const itemId = encodeURIComponent(window.btoa(row.fgId));
          router.navigate("item", { id: itemId });
        }}
        onPaginationChange={(newValue) => setPagination(newValue, true)}
        totalRows={totalRows}
        currentPage={pageIndex}
        pageSize={pageSize}
        loading={loading}
        showNoResult={showNoResult}
        noResultText={noResultText}
      />
    </>
  );
};
