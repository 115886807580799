import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";

export function useAuthenticated() {
  const { user, authStatus } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);

  const [isAuthenticated, setIsAuthenticated] = useState(
    authStatus === "authenticated"
  );

  useEffect(() => {
    if (authStatus === "authenticated") {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    return () => setIsAuthenticated(false);
  }, [authStatus, user]);

  if (isAuthenticated) {
    return user;
  }
  return undefined;
}
