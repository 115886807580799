import { Router } from "router5";

import { client } from "common/client";

export function navigateToItemByFgId(router: Router, fgId: string) {
  const itemId = encodeURIComponent(window.btoa(fgId));
  router.navigate("item", { id: itemId });
}

export function navigateToItemByName(router: Router, name: string) {
  const urlName = encodeURIComponent(name);
  router.navigate("item", { name: urlName });
}

export function inventoryUpdate(
  fgId: string,
  data: { [key: string]: boolean }
) {
  return client.put("/api/inventory", {
    fgId,
    ...data,
  });
}
