import { useEffect, useMemo, useRef, useState } from "react";
import { Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import debounce from "lodash.debounce";

import { Spinner } from "common/components/Spinner";

interface ComponentProps {
  handleSearch: (value: string) => void;
  initialSearchValue: string;
  loading: boolean;
}

export const SearchInput = ({ handleSearch, initialSearchValue, loading }: ComponentProps) => {
  const inputRef = useRef<any>(null);
  const [searchValue, setSearchValue] = useState<string>(initialSearchValue || "");
  const debouncedSearch = useMemo(() => {
    return debounce((value: string) => handleSearch(value), 300);
  }, [handleSearch]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Combobox value={searchValue}>
      <Combobox.Input
        ref={inputRef}
        placeholder="Search"
        onChange={(event) => {
          setSearchValue(event.target.value);
          debouncedSearch(event.target.value);
        }}
        className="w-full rounded-lg bg-white py-2 pl-3 pr-10 ring-1 shadow-inner focus:outline-none focus:ring-2 focus:ring-zinc-700 sm:text-sm text-black"
      />
      <button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
        {loading ? (
          <div className="w-5 h-5">
            <Spinner />
          </div>
        ) : (
          <MagnifyingGlassIcon className="w-6 h-6 text-primary" />
        )}
      </button>
    </Combobox>
  );
};
