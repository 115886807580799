import { useRouter } from "react-router5";
import { UserCircleIcon } from "@heroicons/react/20/solid";
import { useAuthenticated } from "auth/hooks";

export const UserMenu = ({ className }: { className?: string }) => {
  const router = useRouter();
  const user = useAuthenticated();
  if (user === undefined) {
    return null;
  }

  return (
    <button
      className={className ? className : "flex text-sm rounded-md px-4 py-2"}
      title={user.attributes?.email}
      onClick={() => router.navigate("profile")}
    >
      <UserCircleIcon className="h-5 w-5 mr-1" />
      <span className="overflow-hidden text-ellipsis">
        {user.attributes?.email}
      </span>
    </button>
  );
};
