import { RouteDefinition } from "../common/router";
import { AdvancedSearchPage } from "./pages/AdvancedSearchPage";

const routes: RouteDefinition[] = [
  {
    title: "Advanced search",
    name: "advanced_search",
    path: "/search?category&mastered&wishlisted&acquired&name&page&limit",
    render: ({ category, mastered, wishlisted, acquired, name, page, limit }) => {
      let cat = category;
      if (Array.isArray(cat)) {
        cat = cat.map((v) => v.toLowerCase());
      } else if (cat !== undefined) {
        cat = [cat.toLowerCase()];
      }
      return (
        <AdvancedSearchPage
          category={cat}
          mastered={mastered === undefined ? undefined : mastered === "true"}
          wishlisted={wishlisted === undefined ? undefined : wishlisted === "true"}
          acquired={acquired === undefined ? undefined : acquired === "true"}
          name={name}
          page={page ? Number(page) - 1 : 0}
          limit={limit ? Number(limit) : 10}
        />
      );
    },
  },
];
export default routes;
