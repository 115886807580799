import { VideoCameraSlashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Spinner } from "./Spinner";

interface ComponentProps {
  src: string | undefined;
}

export const LoadImage = ({ src }: ComponentProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (src === undefined) {
      setError(true);
      return;
    }
    setError(false);
    setLoading(true);
    const newImage = new Image();
    newImage.onload = () => {
      setLoading(false);
    };
    newImage.onerror = () => {
      setLoading(false);
      setError(true);
    };
    newImage.src = src;
  }, [src]);

  if (error) {
    return (
      <div
        style={{
          height: "inherit",
          maxHeight: "inherit",
          maxWidth: "50px",
          width: "inherit",
          minWidth: "inherit",
        }}
      >
        <VideoCameraSlashIcon />
      </div>
    );
  }

  if (loading) {
    return (
      <div
        style={{
          height: "inherit",
          maxHeight: "inherit",
          maxWidth: "50px",
          minWidth: "inherit",
        }}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <img
      style={{ height: "inherit", maxHeight: "inherit" }}
      alt={src}
      src={src}
    />
  );
};

export default LoadImage;
