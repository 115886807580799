import "./App.css";
import { useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-export";
import { useRoute } from "./common/router";
import { observer } from "mobx-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthenticator } from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";

import { NavBar } from "./common/components/NavBar";
import { Spinner } from "common/components/Spinner";
import { Footer } from "common/components/Footer";

const local = localStorage.getItem("fg-remember-me");

Amplify.configure({
  ...awsExports,
  storage: local !== null ? localStorage : sessionStorage,
});

const App = observer(() => {
  const { route } = useRoute();
  const { route: authRoute } = useAuthenticator((context) => [context.route]);

  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowSpinner(true);
    }, 200);
  }, []);

  // Wait until authentication status is known
  if (authRoute === "idle") {
    if (!showSpinner) {
      return null;
    }
    return (
      <div className="w-full mx-auto justify-center grid content-center h-screen fixed">
        <div className="w-40">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-stone-50 min-h-[100vh] h-full flex flex-col">
      <NavBar />
      <div className="pt-[90px] lg:pt-[110px] font-sans px-1 sm:px-none container mx-auto h-full">
        <h1 className="text-2xl">{route.title}</h1>
        {route.render()}
      </div>
      <div className="flex-grow flex items-end">
        <Footer />
      </div>
      <ToastContainer hideProgressBar />
    </div>
  );
});

export default App;
